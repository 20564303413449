import Heading from "../Heading";
import "./OrderFormOptions.scss";

type FormType = "login" | "register" | "order";

interface OrderFormOptionsProps {
  value: FormType | undefined;
  onChange: (value: FormType | undefined) => void;
}

const OrderFormOptions = ({ value, onChange }: OrderFormOptionsProps) => {
  return (
    <div className="orderFormOptions">
      <Heading>Choose your checkout option</Heading>
      <div className="orderFormOptions-list">
        <div className="orderFormOptions-option">
          <input
            type="radio"
            id="order_form_option_login"
            checked={value === "login"}
            onChange={() => onChange("login")}
          />
          <label htmlFor="order_form_option_login">
            Log in (I already have an account)
          </label>
        </div>
        <div className="orderFormOptions-option">
          <input
            type="radio"
            id="order_form_option_register"
            checked={value === "register"}
            onChange={() => onChange("register")}
          />
          <label htmlFor="order_form_option_register">
            Register (I do not have an account)
          </label>
        </div>
        <div className="orderFormOptions-option">
          <input
            type="radio"
            id="order_form_option_order"
            checked={value === "order"}
            onChange={() => onChange("order")}
          />
          <label htmlFor="order_form_option_order">
            Guest checkout (without registration)
          </label>
        </div>
      </div>
    </div>
  );
};
export default OrderFormOptions;
