import { useQuery } from "@tanstack/react-query";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";

export const POPUP_MESSAGE_QUERY_KEY = "popup-message";

export interface PopupMessageResponse {
  popup_message: {
    id: number;
    title: string;
    content: string;
    button_text: string | null;
    button_url: string | null;
  } | null;
}

export const useGetPopupMessage = (messageId: number | "active" = "active") => {
  return useQuery<unknown, ApiError, PopupMessageResponse>({
    queryKey: [POPUP_MESSAGE_QUERY_KEY, { messageId }],
    queryFn: () => fetchFromApi(`/popup-messages/${messageId}`),
  });
};
