import { useForm, useWatch } from "react-hook-form";
import Checkbox from "../FormControls/CheckBox";
import SubmitButton from "../FormControls/SubmitButton";
import TextArea from "../FormControls/TextArea";
import TextInput from "../FormControls/TextInput";

import { useEffect } from "react";
import { useGetMyProfileQuery } from "../../api/authApi";
import { OrderParams, useCreateOrderMutation } from "../../api/ordersApi";
import Loader from "../Loader";

import "./OrderForm.scss";
import ecommerceGA from "../../utils/ecommerceGA";

const OrderForm = () => {
  const { control, handleSubmit, setError, reset } = useForm<OrderParams>({
    defaultValues: {
      full_name: "",
      company_name: "",
      company_number: "",
      address_line1: "",
      address_line2: "",
      zipcode: "",
      phone_number: "",
      email: "",
      details: "",
      custom_delivery_address: false,
      delivery_full_name: "",
      delivery_company_name: "",
      delivery_company_number: "",
      delivery_address_line1: "",
      delivery_address_line2: "",
      delivery_zipcode: "",
      delivery_phone_number: "",
      delivery_email: "",
      delivery_details: "",
      accept_terms: false,
    },
  });

  const hasCustomDeliveryAddress = useWatch({
    control,
    name: "custom_delivery_address",
  });

  const { currentCustomer } = useGetMyProfileQuery();

  const { mutate: createOrder, isPending: createOrderIsPending } =
    useCreateOrderMutation();

  useEffect(() => {
    if (currentCustomer !== undefined) {
      reset({
        full_name: currentCustomer.full_name ?? "",
        company_name: currentCustomer.company_name ?? "",
        company_number: currentCustomer.company_number ?? "",
        address_line1: currentCustomer.address_line1 ?? "",
        address_line2: currentCustomer.address_line2 ?? "",
        zipcode: currentCustomer.zipcode ?? "",
        city: currentCustomer.city ?? "",
        phone_number: currentCustomer.phone_number ?? "",
        email: currentCustomer.email ?? "",
        custom_delivery_address: false,
        delivery_full_name: currentCustomer.delivery_full_name ?? "",
        delivery_company_name: currentCustomer.delivery_company_name ?? "",
        delivery_company_number: currentCustomer.delivery_company_number ?? "",
        delivery_address_line1: currentCustomer.delivery_address_line1 ?? "",
        delivery_address_line2: currentCustomer.delivery_address_line2 ?? "",
        delivery_zipcode: currentCustomer.delivery_zipcode ?? "",
        delivery_city: currentCustomer.delivery_city ?? "",
        delivery_phone_number: currentCustomer.delivery_company_number ?? "",
        delivery_email: currentCustomer.delivery_email ?? "",
      });
    }
  }, [currentCustomer]);

  const onSubmit = (data: OrderParams) => {
    createOrder(data, {
      onSuccess: ({ order, payment_url }) => {
        ecommerceGA.purchase(order);
        ecommerceGA.addShippingInfo(order);
        ecommerceGA.addPaymentInfo(order, "Stripe");
        window.history.replaceState(
          {},
          document.title,
          `/my-order/${order.id}/${order.secret_code}`
        );
        window.location.href = payment_url;
        //window.location.href = `/my-order/${order.id}/${order.secret_code}?payment=1`;
      },
      onError: (error) => {
        if (error.statusCode === 422) {
          Object.entries(error.errors || {}).forEach(([name, message]) => {
            setError(name as keyof OrderParams, {
              message: Array.isArray(message) ? message.join(", ") : message,
            });
          });
        }
      },
    });
  };
  return (
    <form className="orderForm" onSubmit={handleSubmit(onSubmit)}>
      <Loader full loading={createOrderIsPending} />
      <div className="orderForm-section">
        <div className="orderForm-heading">Your details</div>

        <TextInput
          label="Name and Surname"
          required
          name="full_name"
          control={control}
        />

        <TextInput label="Company" name="company_name" control={control} />

        <TextInput
          label="Company number"
          name="company_number"
          control={control}
        />

        <TextInput
          label="Address line 1"
          required
          name="address_line1"
          control={control}
        />

        <TextInput
          label="Address line 2"
          name="address_line2"
          control={control}
        />

        <div className="orderForm-locationInputs">
          <TextInput
            label="Post Code"
            required
            name="zipcode"
            control={control}
          />
          <TextInput label="City" required name="city" control={control} />
        </div>

        <TextInput
          label="Phone number"
          required
          name="phone_number"
          control={control}
        />
        <TextInput
          label="E-mail"
          type="email"
          required
          name="email"
          control={control}
        />

        <TextArea
          label="Additional comments"
          name="details"
          control={control}
        />

        <Checkbox
          label="Different delivery address?"
          name="custom_delivery_address"
          control={control}
        />
      </div>
      {hasCustomDeliveryAddress && (
        <div className="orderForm-section">
          <div className="orderForm-heading">Delivery address</div>

          <TextInput
            label="Name and Surname"
            required
            name="delivery_full_name"
            control={control}
          />

          <TextInput
            label="Company"
            name="delivery_company_name"
            control={control}
          />

          <TextInput
            label="Company number"
            name="delivery_company_number"
            control={control}
          />

          <TextInput
            label="Address line 1"
            required
            name="delivery_address_line1"
            control={control}
          />

          <TextInput
            label="Address line 2"
            name="delivery_address_line2"
            control={control}
          />

          <div className="orderForm-locationInputs">
            <TextInput
              label="Post Code"
              required
              name="delivery_zipcode"
              control={control}
            />
            <TextInput
              label="City"
              required
              name="delivery_city"
              control={control}
            />
          </div>

          <TextInput
            label="Phone number"
            required
            name="delivery_phone_number"
            control={control}
          />
          <TextInput
            label="E-mail"
            type="email"
            required
            name="delivery_email"
            control={control}
          />

          <TextArea
            label="Additional comments"
            name="delivery_details"
            control={control}
          />
        </div>
      )}

      <Checkbox
        label={
          <>
            By clicking Complete checkout you accept our{" "}
            <a href="/s/terms-conditions" target="_blank">
              Terms and Conditions
            </a>
          </>
        }
        required
        name="accept_terms"
        control={control}
      />
      <SubmitButton disabled={createOrderIsPending}>
        Complete checkout
      </SubmitButton>
    </form>
  );
};
export default OrderForm;
