import { Fragment } from "react";
import ProductType from "../../types/ProductType";
import formatPrice from "../../utils/formatPrice";
import Link from "../Link";
import BasketButton from "../Products/BasketButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import "./WishListItem.scss";
import { useRemoveProductFromWishlistMutation } from "../../api/wishlistApi";
import Loader from "../Loader";
import striptags from "striptags";

interface WishListItemProps {
  item: ProductType;
}

const WishListItem = ({ item }: WishListItemProps) => {
  const productUrl = `/product/${item.slug}`;

  const { mutate: removeWishListItem, isPending: removeWishListItemIsPending } =
    useRemoveProductFromWishlistMutation();

  const handleRemove = () => {
    removeWishListItem({ productId: item.id });
  };

  return (
    <div className="wishListItem">
      <Loader loading={removeWishListItemIsPending} />
      <div className="wishListItem-image">
        <h1 className="wishListItem-title">
          <Link href={productUrl}>{item.name}</Link>
        </h1>

        <div className="wishListeItem-picture">
          {item.medium_image_path !== null ? (
            <Link href={productUrl}>
              <img src={item.medium_image_path} alt={item.name} />
            </Link>
          ) : null}
        </div>
      </div>
      <div className="wishListItem-description">
        {item.description !== null ? (
          <div className="wishListItem-descriptionText">
            {striptags(item.description).substring(0, 600)}
          </div>
        ) : null}
        <div className="wishListItem-descriptionActions">
          <Link href={productUrl}>Read more</Link>
        </div>
      </div>
      <div className="wishListItem-details">
        <div className="wishListItem-detailsTop">
          <div className="wishListItem-availability">
            {item.active ? "In stock" : "Out of stock"}
            {item.shipment_info !== null ? (
              <div className="wishListItem-shipmentInfo">
                {item.shipment_info}
              </div>
            ) : null}
          </div>
          <div>
            <div className="wishListItem-price">{formatPrice(item.price)}</div>
            <div className="wishListItem-delivery">
              Delivery
              <strong>Always free</strong>
            </div>
          </div>
        </div>

        <div className="wishListItem-basket">
          <BasketButton product={item} />
        </div>

        <div className="wishListItem-icons">
          <div className="wishListItem-icon insurance">Secure parcel</div>
          <div className="wishListItem-icon delivery">Courier delivery</div>
          {item.allow_returns && (
            <div className="wishListItem-icon returns">14 days for returns</div>
          )}
        </div>
      </div>

      <button
        className="wishListItem-remove"
        type="button"
        onClick={handleRemove}
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
  );
};
export default WishListItem;
