import { InputHTMLAttributes } from "react";

import classNames from "classnames";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import FormField from "./FormField";
import "./TextInput.scss";

type TextInputProps<TFieldValues extends FieldValues = FieldValues> =
  InputHTMLAttributes<HTMLInputElement> & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
    label: string;
  };

const TextInput = <TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  label,
  ...props
}: TextInputProps<TFieldValues>) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ control, name });

  const inputId = props.id || name;

  return (
    <FormField
      error={error?.message}
      inputId={inputId}
      label={label}
      required={props.required}
    >
      <input
        type="text"
        {...props}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        id={inputId}
        name={name}
        className={classNames("textInput", props.className)}
      />
    </FormField>
  );
};
export default TextInput;
