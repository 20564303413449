import { useGetCategoryArticlesQuery } from "../../api/categoriesApi";
import ArticleItem from "../Articles/ArticleItem";

import "./CatalogArticles.scss";

interface CatalogArticlesProps {
  category: string;
}

const CatalogArticles = ({ category }: CatalogArticlesProps) => {
  const { data: articlesData, isLoading: articlesIsLoading } =
    useGetCategoryArticlesQuery(category);

  if (articlesIsLoading) {
    return null;
  }
  return (
    <div className="catalogArticles">
      <h2 className="catalogArticles-heading">
        Recommended
        <span>articles</span>
      </h2>
      <div className="catalogArticles-list">
        {articlesData?.articles.map((article) => (
          <ArticleItem
            key={article.id}
            article={article}
            className="articleItem-grid"
          />
        ))}
      </div>
    </div>
  );
};
export default CatalogArticles;
