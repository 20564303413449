import OrderItemType from "../../types/OrderItemType";
import OrderItem from "./OrderItem";

import "./OrderItems.scss";

interface OrderItemsProps {
  orderItems: OrderItemType[];
}
const OrderItems = ({ orderItems }: OrderItemsProps) => {
  return (
    <div className="orderItems">
      {orderItems.map((orderItem) => (
        <OrderItem key={orderItem.id} orderItem={orderItem} />
      ))}
    </div>
  );
};
export default OrderItems;
