import ReactGA from "react-ga4";

import BasketType from "../types/BasketType";
import OrderType from "../types/OrderType";
import ProductType from "../types/ProductType";

const ecommerceGA = {
  viewCart: (basket: BasketType) => {
    ReactGA.event("view_cart", {
      currency: "GBP",
      value: Number(basket.total_price),
      items: basket.basket_items.map((item, index) => ({
        item_id: item.product.id,
        item_name: item.product.name,
        item_brand: item.product.producer_name,
        item_category: item.product.category_name,
        price: Number(item.unit_price),
        quantity: item.quantity,
        index: index,
      })),
    });
  },

  beginCheckout: (basket: BasketType) => {
    ReactGA.event("begin_checkout", {
      currency: "GBP",
      value: Number(basket.total_price),
      items: basket.basket_items.map((item, index) => ({
        item_id: item.product.id,
        item_name: item.product.name,
        item_brand: item.product.producer_name,
        item_category: item.product.category_name,
        price: Number(item.unit_price),
        quantity: item.quantity,
        index: index,
      })),
    });
  },

  purchase: (order: OrderType) => {
    ReactGA.event("purchase", {
      transaction_id: order.id,
      affiliation: "Online Store",
      value: Number(order.total_value),
      currency: "GBP",
      // tax: Number(order.total_tax),
      shipping: 0,
      items: order.items.map((item, index) => ({
        item_id: item.product.id,
        item_name: item.product.name,
        item_brand: item.product.producer_name,
        item_category: item.product.category_name,
        price: Number(item.unit_price),
        quantity: item.quantity,
        index: index,
      })),
    });
  },

  addToCart: (product: ProductType) => {
    ReactGA.event("add_to_cart", {
      currency: "GBP",
      value: Number(product.current_price),
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          item_brand: product.producer_name,
          item_category: product.category_name,
          price: Number(product.current_price),
        },
      ],
    });
  },

  viewItemList: (products: ProductType[]) => {
    ReactGA.event("view_item_list", {
      item_list_id: "catalog",
      item_list_name: "Catalog",
      items: products.map((product, index) => ({
        item_id: product.id,
        item_name: product.name,
        item_brand: product.producer_name,
        item_category: product.category_name,
        price: Number(product.current_price),
        index: index,
      })),
    });
  },

  addToWishlist: (product: ProductType) => {
    ReactGA.event("add_to_wishlist", {
      currency: "GBP",
      value: Number(product.current_price),
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          item_brand: product.producer_name,
          item_category: product.category_name,
          price: Number(product.current_price),
        },
      ],
    });
  },

  addShippingInfo: (order: OrderType) => {
    ReactGA.event("add_shipping_info", {
      currency: "GBP",
      value: Number(order.total_value),
      items: order.items.map((item, index) => ({
        item_id: item.product.id,
        item_name: item.product.name,
        item_brand: item.product.producer_name,
        item_category: item.product.category_name,
        price: Number(item.unit_price),
        quantity: item.quantity,
        index: index,
      })),
    });
  },

  addPaymentInfo: (order: OrderType, paymentType: string) => {
    ReactGA.event("add_payment_info", {
      currency: "GBP",
      value: Number(order.total_value),
      payment_type: paymentType,
      items: order.items.map((item, index) => ({
        item_id: item.product.id,
        item_name: item.product.name,
        item_brand: item.product.producer_name,
        item_category: item.product.category_name,
        price: Number(item.unit_price),
        quantity: item.quantity,
        index: index,
      })),
    });
  },

  login: (method: string) => {
    ReactGA.event("login", {
      method,
    });
  },
  signUp: (method: string) => {
    ReactGA.event("sign_up", {
      method,
    });
  },
};

export default ecommerceGA;
