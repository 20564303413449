import { useQuery } from "@tanstack/react-query";
import StaticPageType from "../types/StaticPageType";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";

const TEXT_PAGE_QUERY_KEY = "text-page";

interface StaticPageResponse {
  static_page: StaticPageType;
}

export const useGetStaticPageQuery = (slug: string) => {
  return useQuery<unknown, ApiError, StaticPageResponse>({
    queryKey: [TEXT_PAGE_QUERY_KEY, { slug }],
    queryFn: () => fetchFromApi(`/static-pages/${slug}`),
  });
};
