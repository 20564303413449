import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";

const SETTINGS_QUERY_KEY = "settings";

export interface SettingsResponse {
  settings: {
    site_name: string;
    site_phone: string;
    site_email: string;
    facebook_fanpage: string;
    tiktok_profile: string;
    googleplus_page: string;
    youtube_channel: string;
    instagram_page: string;
    messenger_name: string;
  };
}

export const useGetSettingsQuery = (
  options?: Omit<
    UseQueryOptions<SettingsResponse, ApiError, SettingsResponse>,
    "queryFn" | "queryKey"
  >
) => {
  return useQuery<SettingsResponse, ApiError, SettingsResponse>({
    ...options,
    queryFn: () => fetchFromApi("/settings"),
    queryKey: [SETTINGS_QUERY_KEY],
  });
};
