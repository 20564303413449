import formatPrice from "../../utils/formatPrice";

import "./BasketSummary.scss";

interface BasketSummaryProps {
  basket: {
    total_price: number;
  };
}

const BasketSummary = ({ basket }: BasketSummaryProps) => {
  return (
    <div className="basketSummary">
      <div className="basketSummary__row basketSummary__row-price">
        <div className="basketSummary__row-label">Price</div>
        <div className="basketSummary__row-value">
          {formatPrice(basket.total_price)}
        </div>
      </div>
      <div className="basketSummary__row basketSummary__row-delivery">
        <div className="basketSummary__row-label">Delivery</div>
        <div className="basketSummary__row-value">Always free delivery</div>
      </div>
      <div className="basketSummary__row basketSummary__row-courier">
        <div className="basketSummary__row-label">Courier</div>
        <div className="basketSummary__row-value">
          <span className="courier-logo"></span>
        </div>
      </div>
      <div className="basketSummary__row basketSummary__row-total">
        <div className="basketSummary__row-label">Total</div>
        <div className="basketSummary__row-value">
          {formatPrice(basket.total_price)}
        </div>
      </div>
    </div>
  );
};
export default BasketSummary;
