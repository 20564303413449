import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetMyProfileQuery } from "../../api/authApi";

import "./AccountDetailsBox.scss";
import { Link, useNavigate } from "react-router-dom";

interface AccountDetailsBoxProps {
  heading: string;
  type: "billing" | "delivery";
  children: React.ReactNode;
}

const AccountDetailsBox = ({
  heading,
  children,
  type,
}: AccountDetailsBoxProps) => {
  const { currentCustomerIsLogged } = useGetMyProfileQuery();

  if (!currentCustomerIsLogged) {
    return null;
  }

  return (
    <div className="accountDetailsBox">
      <div className="accountDetailsBox-header">
        <h3>{heading}</h3>
        <Link to={`/my-settings/${type}-address`}>
          <FontAwesomeIcon icon={faEdit} />
          edit
        </Link>
      </div>

      <div className="accountDetailsBox-details">{children}</div>
    </div>
  );
};
export default AccountDetailsBox;
