import { useForm } from "react-hook-form";
import SubmitButton from "../FormControls/SubmitButton";
import TextInput from "../FormControls/TextInput";

import {
  SignInParams,
  useSignInCustomerMutation,
  useSignInCustomerWithGoogleMutation,
} from "../../api/authApi";
import Heading from "../Heading";

import { GoogleOAuthProvider } from "@react-oauth/google";

import Subheading from "../Subheading";
import "./AuthForm.scss";
import GoogleSignInButton from "./GoogleSignInButton";
import { Link } from "react-router-dom";
import ecommerceGA from "../../utils/ecommerceGA";

interface LoginFormProps {
  setLoading: (value: boolean) => void;
  onSuccess?: () => void;
}

const LoginForm = ({ setLoading, onSuccess }: LoginFormProps) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignInParams>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const { mutate: signIn, isPending: signInIsPending } =
    useSignInCustomerMutation();
  const { mutate: signInWithGoogle, isPending: signInWithGoogleIsPending } =
    useSignInCustomerWithGoogleMutation();

  const handleSignInWithGoogle = (token: string) => {
    setLoading(true);
    signInWithGoogle(
      { token },
      {
        onSuccess: () => {
          ecommerceGA.login("Google");
          onSuccess?.();
        },
        onSettled: () => setLoading(false),
        onError: (error) => {
          if (error.statusCode === 422) {
            Object.entries(error.errors || {}).forEach(([name, message]) => {
              setError(name as keyof SignInParams, {
                message: Array.isArray(message) ? message.join(", ") : message,
              });
            });
          }
        },
      }
    );
  };

  const onSubmit = (data: SignInParams) => {
    setLoading(true);
    signIn(data, {
      onSuccess: () => {
        ecommerceGA.login("Email");
        onSuccess?.();
      },
      onSettled: () => setLoading(false),
      onError: (error) => {
        if (error.statusCode === 422) {
          Object.entries(error.errors || {}).forEach(([name, message]) => {
            setError(name as keyof SignInParams, {
              message: Array.isArray(message) ? message.join(", ") : message,
            });
          });
        }
      },
    });
  };

  return (
    <GoogleOAuthProvider clientId="721248724286-e92ng0o5u66pp3scanoorqpk62m72mrj.apps.googleusercontent.com">
      <form onSubmit={handleSubmit(onSubmit)} className="authForm">
        <Heading>Log in</Heading>
        <Subheading>I already have an account</Subheading>
        <TextInput
          label="Email address"
          type="email"
          required
          autoFocus
          name="email"
          control={control}
        />
        <TextInput
          label="Password"
          type="password"
          required
          name="password"
          control={control}
        />
        <div className="authForm-actions">
          <SubmitButton className="authForm-submit" disabled={signInIsPending}>
            Log in
          </SubmitButton>
        </div>
        <div className="authForm-links">
          <GoogleSignInButton onSuccess={handleSignInWithGoogle} />
        </div>

        <div className="authForm-links">
          <Link to="/customers/password/new">Forgotten password?</Link>
        </div>
      </form>
    </GoogleOAuthProvider>
  );
};
export default LoginForm;
