import { useParams } from "react-router-dom";
import LoadingContainer from "../components/LoadingContainer";

import { useEffect, useRef, useState } from "react";
import CatalogArticles from "../components/Catalog/CatalogArticles";
import CatalogDescription from "../components/Catalog/CatalogDescription";
import CatalogSidebar from "../components/Catalog/CatalogSidebar";
import CategoryBestsellers from "../components/Catalog/CategoryBestsellers";
import SortDropdown from "../components/Catalog/SortDropdown";
import Pagination from "../components/Pagination";
import ProductsList from "../components/Products/ProductsList";
import SubpageDescription from "../components/SubpageDescription";
import useCatalog from "../hooks/useCatalog";
import { getPageParam } from "../utils/getParam";
import "./CatalogPage.scss";
import ecommerceGA from "../utils/ecommerceGA";

const CatalogPage = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const page = getPageParam();
  const params = useParams();
  const categorySlug = params.category ?? "";

  const {
    products,
    meta,
    filters,
    isLoading,
    title,
    description,
    image,
    breadcrumbs,
    currentUrl,
    category,
    extraParams,
    apiParams,
  } = useCatalog(params, page);
  const resultsDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (products !== undefined && products.length > 0) {
      ecommerceGA.viewItemList(products);
    }
  }, [products]);

  const handleChangePage = () => {
    window.scrollTo({
      top: resultsDivRef.current?.offsetTop ?? 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <LoadingContainer
      loading={isLoading}
      id="catalogPage"
      breadcrumbsProps={{
        items: category ? [...breadcrumbs, { label: category.name }] : [],
      }}
    >
      <SubpageDescription
        defaultTitle={title}
        defaultDescription={description}
        defaultImage={image}
      />

      <div className="catalogColumns">
        <CatalogSidebar
          filters={filters}
          params={{ ...extraParams, category: categorySlug }}
          mobileOpen={mobileOpen}
          onMobileOpenChange={setMobileOpen}
        />
        <div className="catalogResults" ref={resultsDivRef}>
          <div className="catalogProducts">
            <div className="catalogProducts-navigation">
              <div className="catalogProducts-navigationLeft">
                <button
                  type="button"
                  onClick={() => setMobileOpen(true)}
                  className="catalogPage-filterButton"
                >
                  Filter
                </button>
                <SortDropdown
                  params={{ ...extraParams, category: categorySlug }}
                />
              </div>

              <Pagination
                url={currentUrl}
                meta={meta}
                onChange={handleChangePage}
              />
            </div>
            <ProductsList products={products} className="catalogProductsList" />
            <Pagination
              url={currentUrl}
              meta={meta}
              onChange={handleChangePage}
            />
          </div>
        </div>
      </div>
      {category ? <CatalogArticles category={categorySlug} /> : null}
      {category && <CategoryBestsellers category={category} />}

      <CatalogDescription params={apiParams} />
    </LoadingContainer>
  );
};

export default CatalogPage;
