import { useForm } from "react-hook-form";
import SubmitButton from "../FormControls/SubmitButton";
import TextInput from "../FormControls/TextInput";

import {
  ContactParams,
  useSendContactMessageMutation,
} from "../../api/contactApi";
import Checkbox from "../FormControls/CheckBox";

import { useEffect } from "react";
import { useGetMyProfileQuery } from "../../api/authApi";
import TextArea from "../FormControls/TextArea";
import "./ContactForm.scss";
import Loader from "../Loader";
import ConfirmationLayer from "../Utils/ConfirmationLayer";

const ContactForm = () => {
  const { currentCustomer } = useGetMyProfileQuery();

  const { control, handleSubmit, setError, setValue } = useForm<ContactParams>({
    defaultValues: {
      email: "",
      phone_number: "",
      message: "",
      subject: "",
      sign_up_for_newsletter: false,
      accept_terms: false,
    },
  });

  const {
    mutate: sendContactMessage,
    isPending: sendContactMessageIsPending,
    isSuccess: sendContactMessageIsSuccess,
  } = useSendContactMessageMutation();

  useEffect(() => {
    if (currentCustomer) {
      setValue("email", currentCustomer.email ?? "");
    }
  }, [currentCustomer]);

  const onSubmit = (data: ContactParams) => {
    sendContactMessage(data, {
      onSuccess: () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      onError: (error) => {
        if (error.statusCode === 422) {
          Object.entries(error.errors || {}).forEach(([name, message]) => {
            setError(name as keyof ContactParams, {
              message: Array.isArray(message) ? message.join(", ") : message,
            });
          });
        }
      },
    });
  };

  if (sendContactMessageIsSuccess) {
    return (
      <ConfirmationLayer
        title={
          <>
            Thank you
            <br />
            for contacting us
          </>
        }
        message="We have received your message and will get back to you as soon as
  possible."
      />
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="contactForm">
      <Loader full loading={sendContactMessageIsPending} />

      <h3 className="contactForm-heading">Contact</h3>

      <TextInput
        label="Email address"
        type="email"
        required
        control={control}
        name="email"
      />

      <TextInput label="Phone" required control={control} name="phone_number" />

      <TextInput label="Subject" required control={control} name="subject" />
      <TextArea label="Message" required control={control} name="message" />

      <Checkbox
        label="I would like to receive promotions, special offers, and news. You can opt out anytime by contacting us."
        control={control}
        name="sign_up_for_newsletter"
      />

      <Checkbox
        label={
          <>
            I agree for Red Damson to process my personal data in line with
            their{" "}
            <a href="/s/privacy-policy" target="_blank">
              Privacy Policy
            </a>
            .
          </>
        }
        required
        control={control}
        name="accept_terms"
      />
      <div className="contactForm-actions">
        <SubmitButton
          className="contactForm-submit"
          disabled={sendContactMessageIsPending}
        >
          Send
        </SubmitButton>
      </div>
    </form>
  );
};
export default ContactForm;
