import "./ErrorMessage.scss";

interface ErrorMessageProps {
  error: string | string[] | undefined;
}

const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const printError = () => {
    if (typeof error === "string") {
      return error;
    } else {
      return error?.join("\n");
    }
  };
  if (error === undefined) {
    return null;
  }

  return <div className="errorMessage">{printError()}</div>;
};
export default ErrorMessage;
