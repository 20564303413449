import { useQuery } from "@tanstack/react-query";
import SubpageDescriptionType from "../types/SubpageDescriptionType";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";

const SUBPAGE_DESCRIPTION_QUERY_KEY = "subpage-description";

interface SubpageDescriptionResponse {
  subpage_description: SubpageDescriptionType | null;
}

export const useGetSubpageDescriptionQuery = (pageUrl: string) => {
  return useQuery<unknown, ApiError, SubpageDescriptionResponse>({
    queryKey: [SUBPAGE_DESCRIPTION_QUERY_KEY, { pageUrl }],
    queryFn: () => fetchFromApi(`/subpage-descriptions?page_url=${pageUrl}`),
  });
};
