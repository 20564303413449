import ProductType from "../../../types/ProductType";

interface TitleProps {
  product: ProductType;
}

const Title = ({ product }: TitleProps) => {
  return (
    <h2 className="productListItem-title">
      <a href={`/product/${product.slug}`}>{product.name}</a>
    </h2>
  );
};
export default Title;
