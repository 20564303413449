import "./ConfirmationLayer.scss";

interface ConfirmationLayerProps {
  title: string | React.ReactNode;
  message: string | React.ReactNode;
}

const ConfirmationLayer = ({ title, message }: ConfirmationLayerProps) => {
  return (
    <div className="confirmationLayer">
      <h3 className="confirmationLayer-heading">{title}</h3>

      <p className="confirmationLayer-text">{message}</p>
    </div>
  );
};
export default ConfirmationLayer;
