class ApiError extends Error {
  name = "ApiError";

  message: string;

  errors: Record<string, string[]> | undefined;

  statusCode: number;

  constructor(body: any) {
    super();
    this.errors = body.errors;
    this.statusCode = body.statusCode;
    this.message = `API error occurred (${this.statusCode})`;
  }
}

export default ApiError;
