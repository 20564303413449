import { useSearchParams } from "react-router-dom";

const getParam = <T>(
  name: string,
  searchQuery: string = window.location.search
) => {
  const query = new URLSearchParams(searchQuery);
  return (query.get(name) ?? 1) as T;
};

export const getPageParam = (searchQuery: string = window.location.search) => {
  return getParam<number>("page", searchQuery);
};
export const getSortParam = (searchQuery: string = window.location.search) => {
  const sortValue = getParam<string>("sort", searchQuery);
  return sortValue === "price_desc" ||
    sortValue === "popularity" ||
    sortValue === "price_asc" ||
    sortValue === "id_desc"
    ? sortValue
    : undefined;
};

export const usePageParam = () => {
  const [queryParams] = useSearchParams();
  return Number(queryParams.get("page") ?? "1");
};
