import ProductType from "../../../types/ProductType";
import ProductTags from "./ProductTags";

interface ImageProps {
  product: ProductType;
}

const Image = ({ product }: ImageProps) => {
  return (
    <div className="productListItem-image">
      <ProductTags product={product} />
      <a href={`/product/${product.slug}`}>
        {product.image_path == null ? null : (
          <img src={product.image_path} alt={product.name} />
        )}
      </a>
    </div>
  );
};
export default Image;
