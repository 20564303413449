import { GetProductsParams } from "../api/productsApi";

export const catalogUrl = (
  params: GetProductsParams,
  newParam?: string,
  newValue?: string
) => {
  if (newParam && newValue) {
    if (newParam === "category") {
      params = { ...params, [newParam]: newValue };
    } else if (newParam === "producer" || newParam === "series") {
      const oldValues = params[newParam] || [];
      const newValues = oldValues.includes(newValue)
        ? oldValues.filter((v) => v !== newValue)
        : [...oldValues, newValue];
      params = { ...params, [newParam]: newValues };
    } else {
      const oldValues = params.features[newParam] || [];
      const newValues = oldValues.includes(newValue)
        ? oldValues.filter((v) => v !== newValue)
        : [...oldValues, newValue];
      params = {
        ...params,
        features: { ...params.features, [newParam]: newValues },
      };
    }
  }

  const parts = ["catalog", params.category];
  if (params.producer) {
    const producersList = params.producer.filter(
      (item) => item.trim().length > 0
    );
    if (producersList.length > 0) {
      parts.push(`pr-${producersList.join(",")}`);
    }
  }

  if (params.series) {
    const seriesList = params.series.filter((item) => item.trim().length > 0);
    if (seriesList.length > 0) {
      parts.push(`s-${seriesList.join(",")}`);
    }
  }
  Object.entries(params.features).forEach(([filterSlug, values]) => {
    if (values.length > 0) {
      parts.push(`${filterSlug},${values.join(",")}`);
    }
  });
  return `/${parts.join("/")}`;
};

export const catalogResetUrl = (
  params: GetProductsParams,
  resetParam: string
) => {
  const parts = ["catalog", params.category];
  const newParams = { ...params, features: { ...params.features } };
  if (resetParam === "producer" || resetParam === "series") {
    newParams[resetParam] = [];
  } else {
    newParams.features[resetParam] = [];
  }

  if (newParams.producer) {
    const producersList = newParams.producer.filter(
      (item) => item.trim().length > 0
    );
    if (producersList.length > 0) {
      parts.push(`pr-${producersList.join(",")}`);
    }
  }
  if (newParams.series) {
    const seriesList = newParams.series.filter(
      (item) => item.trim().length > 0
    );
    if (seriesList.length > 0) {
      parts.push(`s-${seriesList.join(",")}`);
    }
  }
  Object.entries(newParams.features).forEach(([filterSlug, values]) => {
    if (values.length > 0) {
      parts.push(`${filterSlug},${values.join(",")}`);
    }
  });
  return `/${parts.join("/")}`;
};
