import { ButtonHTMLAttributes, forwardRef } from "react";
import classNames from "classnames";

import "./SubmitButton.scss";

type SubmitButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(
  (props, ref) => {
    return (
      <button
        {...props}
        type={props.type ?? "submit"}
        className={classNames("submitButton", props.className)}
        ref={ref}
      />
    );
  }
);
export default SubmitButton;
