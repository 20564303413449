import LoadingContainer from "../components/LoadingContainer";

import { useGetMyProfileQuery } from "../api/authApi";
import EditAccountForm from "../components/Account/EditAccountForm";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import "./EditAccountPage.scss";

const EditAccountPage = () => {
  const {
    isSuccess: myProfiletIsSuccess,
    currentCustomerIsLogged,
    isError: currentCustomerIsError,
  } = useGetMyProfileQuery();
  const navigate = useNavigate();
  const { type } = useParams<{ type: "billing" | "delivery" }>();

  useEffect(() => {
    if (myProfiletIsSuccess && !currentCustomerIsLogged) {
      toast.info("You are not signed in");
      navigate("/login");
    }
  }, [myProfiletIsSuccess, currentCustomerIsLogged]);

  return (
    <LoadingContainer
      loading={!myProfiletIsSuccess}
      id="editAccountPage"
      breadcrumbsProps={{
        items: [
          { label: "My account", url: "/my-account" },
          { label: "Edit account" },
        ],
      }}
    >
      {myProfiletIsSuccess && <EditAccountForm type={type ?? "billing"} />}
    </LoadingContainer>
  );
};

export default EditAccountPage;
