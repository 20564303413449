import { Helmet } from "react-helmet";

interface MetaTagsProps {
  title?: string;
  description?: string;
  robots?: string[] | null;
}

const MetaTags = ({ title, description, robots }: MetaTagsProps) => {
  return (
    <Helmet>
      <title>
        {title === undefined ? "RedDamson.co.uk" : `${title} - RedDamson.co.uk`}
      </title>
      {description !== undefined && (
        <meta name="description" content={description} />
      )}
      {robots !== null && robots !== undefined && robots.length > 0 && (
        <meta name="robots" content={robots.join(",")} />
      )}
    </Helmet>
  );
};
export default MetaTags;
