import { useParams } from "react-router-dom";
import { useGetSeriesQuery } from "../api/seriesApi";
import Heading from "../components/Heading";
import LoadingContainer from "../components/LoadingContainer";
import MetaTags from "../components/MetaTags";
import SeriesProducts from "../components/Series/SeriesProducts";
import SubpageDescription from "../components/SubpageDescription";
import EditLink from "../components/Utils/EditLink";

const SeriesPage = () => {
  const { slug } = useParams();

  const { data: seriesData, isSuccess: seriesIsSuccess } = useGetSeriesQuery(
    slug ?? ""
  );

  const isLoading = !seriesIsSuccess;

  return (
    <LoadingContainer
      loading={isLoading}
      id="seriesPage"
      breadcrumbsProps={{
        items: [seriesData ? { label: seriesData.series.name } : undefined],
      }}
    >
      {seriesIsSuccess && (
        <>
          <MetaTags
            title={seriesData.series.meta_title || seriesData.series.name}
            description={seriesData.series.meta_description ?? undefined}
          />
          <EditLink url={`/admin/series/${seriesData.series.id}/edit`} />
          <Heading>{seriesData.series.name}</Heading>
          <SubpageDescription
            defaultDescription={seriesData.series.description}
          />
          <SeriesProducts series={seriesData.series} />
          {/* <Content>{seriesData.series.description_long}</Content> */}
        </>
      )}
    </LoadingContainer>
  );
};
export default SeriesPage;
