import { useState } from "react";
import { FilterItem, GetProductsParams } from "../../api/productsApi";

import "./CatalogFilter.scss";
import AnimateHeight from "react-animate-height";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { catalogResetUrl, catalogUrl } from "../../utils/catalogUtils";
import { Link } from "react-router-dom";

interface CatalogFilterProps {
  label: string;
  items: FilterItem[];
  params: GetProductsParams;
  filterType: string;
}

const CatalogFilter = ({
  label,
  items,
  params,
  filterType,
}: CatalogFilterProps) => {
  const [isOpen, setOpen] = useState(false);

  const handleToogle = () => {
    setOpen(!isOpen);
  };

  const filterValues =
    (filterType === "producer" || filterType === "series"
      ? params[filterType]
      : params.features?.[filterType]) ?? [];

  return (
    <div
      className={classNames("catalogFilter", {
        "catalogFilter-collapsed": !isOpen,
      })}
    >
      <div className="catalogFilter-heading" onClick={handleToogle}>
        <h3>{label}</h3>
        <div className="catalogFilter-headingIcon">
          <FontAwesomeIcon icon={faChevronDown} fontSize={12} />
        </div>
      </div>
      <AnimateHeight duration={500} height={isOpen ? "auto" : 0}>
        <div>
          <div className="catalogFilter-items">
            {items.map((item) => (
              <div
                className={classNames("catalogFilter-item", {
                  "catalogFilter-item--active": filterValues.includes(
                    item.slug
                  ),
                })}
                key={item.id}
              >
                <Link to={catalogUrl(params, filterType, item.slug)}>
                  {item.name}
                </Link>
              </div>
            ))}
          </div>
          <div className="catalogFilter-actions">
            <Link to={catalogResetUrl(params, filterType)}>Clear</Link>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};
export default CatalogFilter;
