import qs from "qs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchProductsQuery } from "../api/productsApi";
import Heading from "../components/Heading";
import LoadingContainer from "../components/LoadingContainer";
import MetaTags from "../components/MetaTags";
import Pagination from "../components/Pagination";
import ProductsList from "../components/Products/ProductsList";
import useQueryParams from "../hooks/useQueryParams";

const SearchResultsPage = () => {
  const query = useQueryParams();
  const navigate = useNavigate();

  const searchText = query.get("s") ?? "";
  const page = Number(query.get("page") ?? 1);

  const {
    data: searchResultsData,
    isLoading: searchResultsIsLoading,
    isSuccess: searchResultsIsSuccess,
  } = useSearchProductsQuery(searchText, page);

  useEffect(() => {
    if (searchResultsIsSuccess && page > searchResultsData.meta.pages) {
      navigate(
        `/search?${qs.stringify({
          s: searchText,
          page: searchResultsData.meta.pages,
        })}`
      );
    }
  }, [searchResultsIsSuccess, page, searchResultsData?.meta.pages]);

  return (
    <LoadingContainer
      id="searchResultsPage"
      loading={searchResultsIsLoading}
      breadcrumbsProps={{
        items: [{ label: "Search results" }],
      }}
    >
      <>
        <MetaTags title={"Search results"} />
        <Heading>
          Search result for <strong>"{searchText}"</strong>
        </Heading>

        <div className="searchResults">
          <Pagination
            url={`/search?${qs.stringify({ s: searchText })}`}
            meta={searchResultsData?.meta}
          />
          <ProductsList products={searchResultsData?.products} />
          <Pagination
            url={`/search?${qs.stringify({ s: searchText })}`}
            meta={searchResultsData?.meta}
          />
        </div>
      </>
    </LoadingContainer>
  );
};
export default SearchResultsPage;
