import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import ProductType from "../types/ProductType";
import ApiError from "./ApiError";
import PaginatedResponse from "./PaginatedResponse";
import fetchFromApi from "./fetchFromApi";

export const PRODUCTS_QUERY_KEY = "products";
export const PRODUCTS_FILTERS_QUERY_KEY = "products-filters";
export const SEARCH_RESULTS_QUERY_KEY = "search-results";

export type SortType = "price_desc" | "price_asc" | "popularity" | undefined;

export interface GetProductsParams {
  category: string;
  producer?: string[];
  series?: string[];
  price_from?: number;
  price_to?: number;
  features: Record<string, string[]>;
  sort?: SortType;
}

interface ProductsResponse {
  products: ProductType[];
}

export interface FilterItem {
  id: number;
  name: string;
  slug: string;
  count: number;
}

export interface FiltersResponse {
  min_price: number;
  max_price: number;
  producers: FilterItem[];
  series: FilterItem[];
  features: {
    name: string;
    slug: string;
    values: FilterItem[];
  }[];
}

export const useSearchProductsQuery = (query: string, page = 1) => {
  return useQuery<unknown, ApiError, PaginatedResponse<ProductsResponse>>({
    queryKey: [SEARCH_RESULTS_QUERY_KEY, { query, page }],
    queryFn: () =>
      fetchFromApi(
        `/products/search?${qs.stringify(
          { s: query, page, limit: 9 },
          {
            arrayFormat: "brackets",
          }
        )}`
      ),
  });
};

export const useGetProductsQuery = (
  params: GetProductsParams,
  page: number = 1,
  sort: SortType = undefined
) => {
  return useQuery<unknown, ApiError, PaginatedResponse<ProductsResponse>>({
    queryKey: [PRODUCTS_QUERY_KEY, { params, page, sort }],
    queryFn: () =>
      fetchFromApi(
        `/products/${qs.stringify(
          { filters: params, page, sort },
          { addQueryPrefix: true, arrayFormat: "brackets" }
        )}`
      ),
  });
};

export const useGetProductFiltersQuery = (
  params: GetProductsParams,
  page: number = 1
) => {
  return useQuery<unknown, ApiError, FiltersResponse>({
    queryKey: [PRODUCTS_FILTERS_QUERY_KEY, { params, page }],
    queryFn: () =>
      fetchFromApi(
        `/products/filters${qs.stringify(
          { filters: params, page },
          { addQueryPrefix: true, arrayFormat: "brackets" }
        )}`
      ),
  });
};
