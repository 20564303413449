import { toast } from "react-toastify";
import {
  ResetPasswordParams,
  useResetPasswordMutation,
} from "../api/resetPasswordApi";
import ResetPasswordForm from "../components/Auth/ResetPasswordForm";
import LoadingContainer from "../components/LoadingContainer";

import "./ResetPasswordPage.scss";
import { useNavigate } from "react-router-dom";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { mutate: resetPassword, isPending: resetPasswordIsPending } =
    useResetPasswordMutation();

  const handleSubmit = (params: ResetPasswordParams) => {
    resetPassword(params, {
      onSuccess: () => {
        toast.success(
          "We sent you an email with a link to reset your password."
        );
        navigate("/login");
      },
    });
  };
  return (
    <LoadingContainer
      id="resetPasswordPage"
      loading={resetPasswordIsPending}
      breadcrumbsProps={{ items: [{ label: "Reset password" }] }}
    >
      <div className="resetPasswordPageContainer">
        <ResetPasswordForm onSubmit={handleSubmit} />
      </div>
    </LoadingContainer>
  );
};

export default ResetPasswordPage;
