import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ContactChannels.scss";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useGetSettingsQuery } from "../../api/settingsApi";

const ContactChannels = () => {
  const { data: settingsData, isLoading: settingsIsLoading } =
    useGetSettingsQuery();

  if (settingsIsLoading) {
    return null;
  }

  const settings = settingsData?.settings;

  return (
    <div className="contactChannels">
      <div className="contactChannels-header">
        Need advice?
        <br />
        Our Customer Service team is available
        <br />
        Monday - Saturday 9 am - 5 pm
      </div>

      {settings?.site_email ? (
        <div className="contactChannels-link email">
          <a href={`mailto:${settings.site_email}`}>{settings.site_email}</a>
        </div>
      ) : null}
      {settings?.site_phone ? (
        <div className="contactChannels-link whatsapp">
          <a href={`https://wa.me/${settings.site_phone.replace(/\s/, "")}`}>
            WhatsApp direct message
          </a>
        </div>
      ) : null}

      {settings?.messenger_name ? (
        <div className="contactChannels-link messenger">
          <a href={`https://m.me/${settings.messenger_name.replace(/\s/, "")}`}>
            Messanger direct message
          </a>
        </div>
      ) : null}

      <hr />

      {settings?.site_phone ? (
        <div className="contactChannels-link phone">
          <a href={`tel:${settings.site_phone.replace(/\s/, "")}`}>
            {settings.site_phone}
          </a>
        </div>
      ) : null}
      <hr />

      <div className="contactChannels-icons">
        {settings?.instagram_page ? (
          <a href={settings.instagram_page} target="_blank">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        ) : null}
        {settings?.facebook_fanpage ? (
          <a href={settings.facebook_fanpage} target="_blank">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        ) : null}
        {settings?.tiktok_profile ? (
          <a href={settings.tiktok_profile} target="_blank">
            <FontAwesomeIcon icon={faTiktok} />
          </a>
        ) : null}
        {settings?.youtube_channel ? (
          <a href={settings.youtube_channel} target="_blank">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        ) : null}
      </div>
    </div>
  );
};
export default ContactChannels;
