import { useParams } from "react-router-dom";
import { useGetOrderQuery } from "../api/ordersApi";
import BasketSummary from "../components/Basket/BasketSummary";
import Columns from "../components/Columns";
import LoadingContainer from "../components/LoadingContainer";
import OrderItems from "../components/NewOrder/OrderItems";
import OrderConfirmation from "../components/Order/OrderConfirmation";

import "./BasketPage.scss";

const OrderPage = () => {
  const { id, secret } = useParams();

  const { data: orderData, isFetching: orderIsFetching } = useGetOrderQuery(
    Number(id),
    secret ?? ""
  );

  const isFetching = orderIsFetching;

  return (
    <LoadingContainer
      loading={isFetching}
      id="orderPage"
      breadcrumbsProps={{
        items: [
          orderData ? { label: `Order #${orderData.order.id}` } : undefined,
        ],
      }}
    >
      {orderData !== undefined && (
        <Columns>
          <div>
            <OrderConfirmation />
          </div>
          <div>
            <OrderItems orderItems={orderData.order.items} />

            <BasketSummary
              basket={{ total_price: orderData.order.total_value }}
            />
          </div>
        </Columns>
      )}
    </LoadingContainer>
  );
};

export default OrderPage;
