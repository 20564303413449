import { useForm } from "react-hook-form";
import TextInput from "../FormControls/TextInput";
import SubmitButton from "../FormControls/SubmitButton";

import Checkbox from "../FormControls/CheckBox";
import { RegisterParams, useRegisterCustomerMutation } from "../../api/authApi";

import "./AuthForm.scss";
import Heading from "../Heading";
import Subheading from "../Subheading";
import Loader from "../Loader";
import ecommerceGA from "../../utils/ecommerceGA";

interface RegisterFormProps {
  onSuccess?: () => void;
}
const RegisterForm = ({ onSuccess }: RegisterFormProps) => {
  const { control, handleSubmit, setError } = useForm<RegisterParams>({
    defaultValues: {
      email: "",
      password: "",
      password_confirmation: "",
      accept_terms: false,
    },
  });

  const { mutate: registerCustomer, isPending: registerCustomerIsPending } =
    useRegisterCustomerMutation();

  const onSubmit = (data: RegisterParams) => {
    registerCustomer(data, {
      onSuccess: () => {
        ecommerceGA.signUp("Email");
        onSuccess?.();
      },
      onError: (error) => {
        if (error.statusCode === 422) {
          Object.entries(error.errors || {}).forEach(([name, message]) => {
            setError(name as keyof RegisterParams, {
              message: Array.isArray(message) ? message.join(", ") : message,
            });
          });
        }
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="authForm">
      <Loader full loading={registerCustomerIsPending} />
      <Heading>Register</Heading>
      <Subheading>Create an account</Subheading>

      <TextInput
        label="Email address"
        type="email"
        required
        name="email"
        control={control}
      />

      <TextInput
        label="Password"
        type="password"
        required
        name="password"
        control={control}
      />
      <TextInput
        label="Password confirmation"
        type="password"
        required
        name="password_confirmation"
        control={control}
      />

      <Checkbox
        label={
          <>
            By clicking REGISTER NOW you accept our{" "}
            <a href="/s/terms-conditions" target="_blank">
              Terms and Conditions
            </a>{" "}
            &amp; the{" "}
            <a href="/s/privacy-policy-and-gdpr" target="_blank">
              Privacy Policy
            </a>
          </>
        }
        required
        name="accept_terms"
        control={control}
      />
      <div className="authForm-actions">
        <SubmitButton
          className="authForm-submit"
          disabled={registerCustomerIsPending}
        >
          Register now
        </SubmitButton>
      </div>
    </form>
  );
};
export default RegisterForm;
