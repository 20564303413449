import { useForm } from "react-hook-form";
import SubmitButton from "../FormControls/SubmitButton";
import TextInput from "../FormControls/TextInput";

import Heading from "../Heading";
import {
  ChangePasswordParams,
  useChangePasswordMutation,
} from "../../api/resetPasswordApi";
import { useEffect } from "react";

import "./AuthForm.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ChangePasswordForm = () => {
  const navigate = useNavigate();
  const resetPasswordToken = new URLSearchParams(window.location.search).get(
    "reset_password_token"
  );
  const { control, handleSubmit, setError } = useForm<ChangePasswordParams>({
    defaultValues: {
      password: "",
      password_confirmation: "",
      reset_password_token: resetPasswordToken ?? "",
    },
  });

  const {
    mutate: changePassword,
    isLoading: changePasswordIsLoading,
    isSuccess: changePasswordIsSuccess,
  } = useChangePasswordMutation();

  useEffect(() => {
    if (!resetPasswordToken) {
      navigate("/customers/password/new");
    }
  }, []);

  const onSubmit = (params: ChangePasswordParams) => {
    changePassword(params, {
      onSuccess: () => {
        toast.success("Your password has been changed. You can now log in.");
        navigate("/login");
      },
      onError: (error) => {
        if (error.statusCode === 422) {
          Object.entries(error.errors || {}).forEach(([name, message]) => {
            setError(name as keyof ChangePasswordParams, {
              message: Array.isArray(message) ? message.join(", ") : message,
            });
          });
        }
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="authForm">
      <Heading>CHANGE YOUR PASSWORD</Heading>

      <TextInput
        label="Password"
        type="password"
        required
        autoFocus
        name="password"
        control={control}
      />

      <TextInput
        label="Password confirmation"
        type="password"
        required
        name="password_confirmation"
        control={control}
      />

      <div className="authForm-actions">
        <SubmitButton className="authForm-submit">Submit</SubmitButton>
      </div>
    </form>
  );
};
export default ChangePasswordForm;
