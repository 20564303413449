import ProductType from "../../../types/ProductType";
import BasketButton from "../BasketButton";

interface ActionsProps {
  product: ProductType;
}

const Actions = ({ product }: ActionsProps) => {
  return (
    <div className="productListItem-actions">
      <BasketButton product={product} />
    </div>
  );
};
export default Actions;
