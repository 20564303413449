import { Fragment } from "react";
import PaginatedResponse from "../api/PaginatedResponse";

import "./Pagination.scss";
import qs from "qs";
import { Link } from "react-router-dom";

interface PaginationProps {
  url: string;
  meta: PaginatedResponse["meta"] | undefined;
  onChange?: (page: number) => void;
}

const toArray = (min: number, max: number) => {
  if (max < min) {
    return [];
  }
  return Array.from({ length: max - min + 1 }, (_, i) => i + min);
};

const Pagination = ({ url, meta, onChange }: PaginationProps) => {
  if (!meta) {
    return null;
  }

  const queryParams = Object.fromEntries(
    new URLSearchParams(url.split("?")[1])
  );
  const urlPath = url.split("?")[0];

  const pageUrl = (page: number) => {
    return `${urlPath}?${qs.stringify({
      ...queryParams,
      page: page === 1 ? undefined : page,
    })}`;
  };

  if (meta.pages <= 1) {
    return null;
  }

  return (
    <div className="pagination">
      {meta.prev_page !== null ? (
        <div className="paginationSection">
          <Link to={pageUrl(1)} onClick={() => onChange?.(1)}>
            &laquo;
          </Link>
          <Link
            to={pageUrl(meta.prev_page)}
            onClick={() => onChange?.(meta.prev_page!)}
          >
            &lsaquo;
          </Link>
        </div>
      ) : null}

      <div className="paginationSection paginationSection-pages">
        {toArray(Math.max(meta.current_page - 3, 1), meta.current_page - 1).map(
          (page) => (
            <Fragment key={page}>
              <Link to={pageUrl(page)} onClick={() => onChange?.(page)}>
                {page}
              </Link>{" "}
            </Fragment>
          )
        )}
        <em>{meta.current_page}</em>
        {toArray(
          meta.current_page + 1,
          Math.min(meta.current_page + 3, meta.pages)
        ).map((page) => (
          <Fragment key={page}>
            <Link to={pageUrl(page)} onClick={() => onChange?.(page)}>
              {page}
            </Link>{" "}
          </Fragment>
        ))}
      </div>

      {meta.next_page !== null ? (
        <div className="paginationSection">
          <Link
            to={pageUrl(meta.next_page)}
            onClick={() => onChange?.(meta.next_page!)}
          >
            &rsaquo;
          </Link>
          <Link to={pageUrl(meta.pages)} onClick={() => onChange?.(meta.pages)}>
            &raquo;
          </Link>
        </div>
      ) : null}
    </div>
  );
};
export default Pagination;
