import classNames from "classnames";
import "./Content.scss";

interface ContentProps {
  children: string | undefined;
  className?: string;
}

const Content = ({ children, className }: ContentProps) => {
  return (
    <div
      className={classNames("textContent", className)}
      dangerouslySetInnerHTML={{ __html: children ?? "" }}
    ></div>
  );
};
export default Content;
