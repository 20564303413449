import { useGetProducersQuery } from "../api/producersApi";
import Heading from "../components/Heading";
import LoadingContainer from "../components/LoadingContainer";
import MetaTags from "../components/MetaTags";
import ProducersList from "../components/Producers/ProducersList";
import SubpageDescription from "../components/SubpageDescription";
import EditLink from "../components/Utils/EditLink";

const ProducersPage = () => {
  const { data: producersData, isSuccess: producersIsSuccess } =
    useGetProducersQuery();

  return (
    <LoadingContainer
      loading={!producersIsSuccess}
      id="producersPage"
      breadcrumbsProps={{ items: [{ label: "All brands", url: "/brands" }] }}
    >
      <MetaTags title="All brands" />
      <EditLink url="/admin/producers" />
      {producersIsSuccess && (
        <>
          <Heading>All brands</Heading>
          <SubpageDescription />
          <ProducersList producers={producersData.producers} />
        </>
      )}
    </LoadingContainer>
  );
};
export default ProducersPage;
