import { useQuery } from "@tanstack/react-query";
import fetchFromApi from "./fetchFromApi";
import ProducerType from "../types/ProducerType";
import ApiError from "./ApiError";
import ProductType from "../types/ProductType";
import ProducerDetailsType from "../types/ProducerDetailsType";
import PaginatedResponse from "./PaginatedResponse";

const PRODUCERS_QUERY_KEY = "producers";
const PRODUCER_QUERY_KEY = "producer";

interface ProducersResponse {
  producers: ProducerType[];
}

interface ProducerResponse {
  producer: ProducerDetailsType;
}

interface ProducerProductsResponse {
  products: ProductType[];
}

export const useGetProducersQuery = () => {
  return useQuery<unknown, ApiError, ProducersResponse>({
    queryKey: [PRODUCERS_QUERY_KEY],
    queryFn: () => fetchFromApi("/producers"),
  });
};

export const useGetProducerQuery = (producerId: string) => {
  return useQuery<unknown, ApiError, ProducerResponse>({
    queryKey: [PRODUCER_QUERY_KEY, producerId],
    queryFn: () => fetchFromApi(`/producers/${producerId}`),
  });
};

export const useGetProducerProductsQuery = (producerId: string, page = 1) => {
  return useQuery<
    unknown,
    ApiError,
    PaginatedResponse<ProducerProductsResponse>
  >({
    queryKey: [PRODUCER_QUERY_KEY, producerId, "products", page],
    queryFn: () =>
      fetchFromApi(`/producers/${producerId}/products?page=${page}`),
  });
};
