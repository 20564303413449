import { useGetWishlistQuery } from "../../api/wishlistApi";
import ProductType from "../../types/ProductType";
import Pagination from "../Pagination";
import WishListItem from "./WishListItem";

interface WishListItemsProps {
  items: ProductType[];
}

const WishListItems = ({ items }: WishListItemsProps) => {
  const query = new URLSearchParams(location.search);
  const page = (query.get("page") ?? 1) as number;

  const { data: wishListData, isSuccess: wishListIsSuccess } =
    useGetWishlistQuery(page);
  return (
    <>
      <div className="wishlist-items">
        {items.map((item) => (
          <WishListItem key={item.id} item={item} />
        ))}
      </div>
      <Pagination url="/wishlist" meta={wishListData?.meta} />
    </>
  );
};
export default WishListItems;
