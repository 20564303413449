import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import BasketType from "../types/BasketType";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";
import BasketItemType from "../types/BasketItemType";
import ProductType from "../types/ProductType";

const BASKET_QUERY_KEY = "basket";

interface BasketResponse {
  basket: BasketType;
}

interface BasketItemResponse {
  basket_item: BasketItemType;
}

interface BasketItemActionParams {
  productId: number;
  quantity?: number;
}

export const useGetBasketQuery = () => {
  return useQuery<unknown, ApiError, BasketResponse>({
    queryKey: [BASKET_QUERY_KEY],
    queryFn: () => fetchFromApi("/basket"),
  });
};

export const useCreateBasketItemQuery = () => {
  const queryClient = useQueryClient();
  return useMutation<BasketItemResponse, ApiError, BasketItemActionParams>({
    mutationFn: ({ productId, quantity = 1 }) =>
      fetchFromApi(`/basket/${productId}/create`, "POST", {
        quantity,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [BASKET_QUERY_KEY] });
      if ("refreshBasketSize" in window) {
        (window.refreshBasketSize as () => void)();
      }
    },
    onSuccess: (data) => {
      if ("showBasketLayer" in window) {
        (window.showBasketLayer as (product: ProductType) => void)(
          data.basket_item.product
        );
      }
    },
  });
};
export const useUpdateBasketItemQuery = () => {
  const queryClient = useQueryClient();
  return useMutation<BasketItemResponse, ApiError, BasketItemActionParams>({
    mutationFn: ({ productId, quantity = 1 }) =>
      fetchFromApi(`/basket/${productId}/update`, "POST", {
        quantity,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [BASKET_QUERY_KEY] });

      if ("refreshBasketSize" in window) {
        (window.refreshBasketSize as () => void)();
      }
    },
  });
};
export const useDestroyBasketItemQuery = () => {
  const queryClient = useQueryClient();
  return useMutation<BasketItemResponse, ApiError, BasketItemActionParams>({
    mutationFn: ({ productId, quantity = 1 }) =>
      fetchFromApi(`/basket/${productId}/destroy`, "DELETE", {
        quantity,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [BASKET_QUERY_KEY] });

      if ("refreshBasketSize" in window) {
        (window.refreshBasketSize as () => void)();
      }
    },
  });
};
