import ProductType from "../../../types/ProductType";
import formatPrice from "../../../utils/formatPrice";

interface PriceProps {
  product: ProductType;
}

const Price = ({ product }: PriceProps) => {
  return (
    <div className="productListItem-price">
      <div className="productListItem-price--current">
        {formatPrice(product.current_price)}
      </div>

      {product.price > product.current_price && (
        <span className="productListItem-price--full">
          {formatPrice(product.price)}
        </span>
      )}
    </div>
  );
};
export default Price;
