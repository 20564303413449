import { useMutation } from "@tanstack/react-query";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";

export interface ContactParams {
  email: string;
  phone_number: string;
  message: string;
  subject: string;
  accept_terms: boolean;
  sign_up_for_newsletter: boolean;
}

export const useSendContactMessageMutation = () => {
  return useMutation<undefined, ApiError, ContactParams>({
    mutationFn: (params) =>
      fetchFromApi("/contact", "POST", { contact_email: params }),
  });
};
