import { useLocation } from "react-router-dom";
import { useGetArticlesQuery } from "../api/articlesApi";
import ArticlesList from "../components/Articles/ArticlesList";
import LoadingContainer from "../components/LoadingContainer";
import MetaTags from "../components/MetaTags";
import Pagination from "../components/Pagination";
import EditLink from "../components/Utils/EditLink";
import { getPageParam, usePageParam } from "../utils/getParam";

const ArticlesPage = () => {
  const page = usePageParam();

  console.log(page);

  const { data: articlesData, isSuccess: articlesIsSuccess } =
    useGetArticlesQuery(page);

  return (
    <LoadingContainer
      id="articlesPage"
      loading={!articlesIsSuccess}
      breadcrumbsProps={{
        items: [{ label: "Articles" }],
      }}
    >
      <MetaTags title="Articles" />
      <EditLink url="/admin/articles" />
      {articlesIsSuccess && <ArticlesList articles={articlesData?.articles} />}
      <Pagination url="/articles" meta={articlesData?.meta} />
    </LoadingContainer>
  );
};
export default ArticlesPage;
