import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";
import CustomerType from "../types/CustomerType";

const MY_PROFILE_QUERY_KEY = "my-profile";
const MY_EMPLOYEE_PROFILE_QUERY_KEY = "my-employee-profile";

interface CustomerResponse {
  customer: CustomerType;
}

interface EmployeeResponse {
  employee: {
    email: string;
    id: number;
  };
}

export interface SignInParams {
  email: string;
  password: string;
}

export interface SignInWithGoogleParams {
  token: string;
}

export interface RegisterParams {
  email: string;
  password: string;
  password_confirmation: string;
  accept_terms: boolean;
}

export interface UpdateAccountParams {
  full_name: string;
  company_name: string;
  company_number: string;
  address_line1: string;
  address_line2: string;
  city: string;
  zipcode: string;
  phone_number: string;
  email: string;
  custom_delivery_address: boolean;
  delivery_full_name: string;
  delivery_company_name: string;
  delivery_company_number: string;
  delivery_address_line1: string;
  delivery_address_line2: string;
  delivery_city: string;
  delivery_zipcode: string;
  delivery_phone_number: string;
  delivery_email: string;
}

export const useGetMyProfileQuery = () => {
  const response = useQuery<CustomerResponse, ApiError, CustomerResponse>({
    queryKey: [MY_PROFILE_QUERY_KEY],
    queryFn: () => fetchFromApi("/auth/profile"),
  });
  return {
    ...response,
    currentCustomer: response.data?.customer,
    currentCustomerIsLogged: response.data?.customer !== undefined,
  };
};

export const useGetMyEmployeeProfileQuery = () => {
  const response = useQuery<EmployeeResponse, ApiError, EmployeeResponse>({
    queryKey: [MY_EMPLOYEE_PROFILE_QUERY_KEY],
    queryFn: () => fetchFromApi("/auth/employee/profile"),
  });
  return {
    ...response,
    currentEmployee: response.data?.employee,
    currentEmployeeIsLogged: response.data?.employee !== undefined,
  };
};

export const useSignInCustomerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<CustomerResponse, ApiError, SignInParams>({
    mutationFn: (params) =>
      fetchFromApi("/auth/sign-in", "POST", { customer: params }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [MY_PROFILE_QUERY_KEY] });
    },
  });
};

export const useSignInCustomerWithGoogleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<CustomerResponse, ApiError, SignInWithGoogleParams>({
    mutationFn: ({ token }) =>
      fetchFromApi("/auth/sign-in/google", "POST", {
        customer: {
          token,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [MY_PROFILE_QUERY_KEY] });
    },
  });
};

export const useRegisterCustomerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<CustomerResponse, ApiError, RegisterParams>({
    mutationFn: (params) =>
      fetchFromApi(`/auth/register`, "POST", { customer: params }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [MY_PROFILE_QUERY_KEY] });
    },
  });
};

export const useUpdateAccountMutation = (
  options: Omit<
    UseMutationOptions<CustomerResponse, ApiError, UpdateAccountParams>,
    "mutationFn"
  > = {}
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: (customer) =>
      fetchFromApi(`/auth/profile`, "POST", { customer }),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [MY_PROFILE_QUERY_KEY] });
      options.onSuccess?.(data, variables, context);
    },
  });
};
