import { useCreateBasketItemQuery } from "../../api/basketApi";
import ProductType from "../../types/ProductType";
import ecommerceGA from "../../utils/ecommerceGA";

import "./BasketButton.scss";

interface BasketButtonProps {
  product: ProductType;
}

const BasketButton = ({ product }: BasketButtonProps) => {
  const { mutate: addToBasket } = useCreateBasketItemQuery();
  const handleClick = () => {
    addToBasket(
      { productId: product.id, quantity: 1 },
      {
        onSuccess: () => {
          ecommerceGA.addToCart(product);
        },
      }
    );
  };

  if (!product.active) {
    return <div className="basketButton--disabled">Product unavailable</div>;
  }

  return (
    <button type="button" className="basketButton" onClick={handleClick}>
      <span>Add to basket</span>
    </button>
  );
};
export default BasketButton;
