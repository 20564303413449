import { useGetMyProfileQuery } from "../api/authApi";
import AccountDetailsBox from "../components/Account/AccountDetailsBox";
import Heading from "../components/Heading";
import LoadingContainer from "../components/LoadingContainer";

import "./AccountPage.scss";

const AccountPage = () => {
  const { currentCustomer } = useGetMyProfileQuery();

  if (!currentCustomer) {
    return null;
  }

  const deliveryPrefix = currentCustomer.custom_delivery_address
    ? "delivery_"
    : "";

  return (
    <LoadingContainer
      id="myAcountPage"
      breadcrumbsProps={{
        items: [{ label: "My account" }],
      }}
    >
      <Heading>My account</Heading>

      <div className="myAccountPage-columns">
        <div className="myAccountPage-column">
          <AccountDetailsBox heading="Billing details" type="billing">
            {currentCustomer.full_name}
            <br />
            {currentCustomer.address_line1}
            {currentCustomer.address_line2 && (
              <>
                <br />
                {currentCustomer.address_line2}
              </>
            )}
            <br />
            {currentCustomer.zipcode} {currentCustomer.city}
            <br />
            {currentCustomer.phone_number}
            <br />
            {currentCustomer.email}
          </AccountDetailsBox>
        </div>

        <div className="myAccountPage-column">
          <AccountDetailsBox heading="Delivery details" type="delivery">
            {currentCustomer[`${deliveryPrefix}full_name`]}
            <br />
            {currentCustomer[`${deliveryPrefix}address_line1`]}
            {currentCustomer[`${deliveryPrefix}address_line2`] && (
              <>
                <br />
                {currentCustomer[`${deliveryPrefix}address_line2`]}
              </>
            )}
            <br />
            {currentCustomer[`${deliveryPrefix}zipcode`]}{" "}
            {currentCustomer[`${deliveryPrefix}city`]}
            <br />
            {currentCustomer[`${deliveryPrefix}phone_number`]}
            <br />
            {currentCustomer[`${deliveryPrefix}email`]}
          </AccountDetailsBox>
        </div>
      </div>
    </LoadingContainer>
  );
};

export default AccountPage;
