import classNames from "classnames";
import { FiltersResponse, GetProductsParams } from "../../api/productsApi";

import CatalogFilter from "./CatalogFilter";

import "./CatalogSidebar.scss";

interface CatalogSidebarProps {
  filters: FiltersResponse | undefined;
  params: GetProductsParams;
  mobileOpen?: boolean;
  onMobileOpenChange?: (open: boolean) => void;
}

const CatalogSidebar = ({
  filters,
  params,
  mobileOpen = !false,
  onMobileOpenChange,
}: CatalogSidebarProps) => {
  const closeMobileSidebar = () => {
    onMobileOpenChange?.(false);
  };
  return (
    <>
      <div
        className={classNames("catalogSidebar", {
          open: mobileOpen,
        })}
      >
        <button
          className="catalogSidebar-close"
          type="button"
          onClick={closeMobileSidebar}
        />
        <h2 className="catalogSidebar-heading">Filter</h2>
        <CatalogFilter
          label="Brand"
          items={filters?.producers ?? []}
          params={params}
          filterType="producer"
        ></CatalogFilter>

        <CatalogFilter
          label="Range"
          items={filters?.series ?? []}
          params={params}
          filterType="series"
        ></CatalogFilter>

        {filters?.features.map((feature) => (
          <CatalogFilter
            key={feature.slug}
            label={feature.name}
            items={feature.values}
            params={params}
            filterType={feature.slug}
          ></CatalogFilter>
        ))}
      </div>
      <button
        className="catalogSidebar-overlay"
        onClick={closeMobileSidebar}
        type="button"
      />
    </>
  );
};
export default CatalogSidebar;
