import classNames from "classnames";

import "./Columns.scss";

interface ColumnsProps {
  children: React.ReactNode;
  className?: string;
}

const Columns = ({ children, className }: ColumnsProps) => {
  return <div className={classNames("pageColumns", className)}>{children}</div>;
};
export default Columns;
