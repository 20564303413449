import { useState } from "react";
import BasketType from "../../types/BasketType";
import BasketItem from "./BasketItem";
import EmptyBasketMessage from "./EmptyBasketMessage";

import { useDestroyBasketItemQuery } from "../../api/basketApi";
import "./BasketItems.scss";

interface BasketItemsProps {
  basket: BasketType;
}
const BasketItems = ({ basket }: BasketItemsProps) => {
  const [productsToDestroy, setProductsToDestroy] = useState<number[]>([]);

  const { mutate: removeProduct } = useDestroyBasketItemQuery();

  const handleToggle = (productId: number) => {
    if (productsToDestroy.includes(productId)) {
      setProductsToDestroy(
        productsToDestroy.filter((item) => item !== productId)
      );
    } else {
      setProductsToDestroy([...productsToDestroy, productId]);
    }
  };

  const handleRemoveProducts = () => {
    const basketItemsToDestroy = basket.basket_items.filter((item) =>
      productsToDestroy.includes(item.product.id)
    );
    basketItemsToDestroy.map((item) => {
      removeProduct({ productId: item.product.id, quantity: item.quantity });
    });
  };

  return (
    <div>
      <div className="basketItems">
        {basket.basket_items.length > 0 ? (
          basket.basket_items.map((basketItem) => (
            <BasketItem
              key={basketItem.id}
              basketItem={basketItem}
              isChecked={productsToDestroy.includes(basketItem.product.id)}
              onToggle={() => handleToggle(basketItem.product.id)}
            />
          ))
        ) : (
          <EmptyBasketMessage />
        )}
      </div>
      <div className="basketItems-actions">
        <a href="/" className="btn-more">
          Continue shopping
          <i className="fa fa-chevron-right"></i>
        </a>
        {basket.basket_items.length > 0 && (
          <button
            onClick={handleRemoveProducts}
            disabled={productsToDestroy.length === 0}
          >
            Delete selected
          </button>
        )}
      </div>
    </div>
  );
};
export default BasketItems;
