import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useGetPopupMessage } from "../api/popupMessagesApi";

import { useEffect, useState } from "react";
import { useGetSettingsQuery } from "../api/settingsApi";
import Content from "./Content";
import "./PopupMessage.scss";

const queryClient = new QueryClient();

const COOKIE_NAME = "rd_popup_messages";

const getShownPopupMessages = () => {
  const shownPopupMessages = Cookies.get(COOKIE_NAME);
  if (shownPopupMessages === undefined) {
    return [];
  }
  const parsedShownPopupMessages = JSON.parse(shownPopupMessages);
  if (!Array.isArray(parsedShownPopupMessages)) {
    return [];
  }
  return parsedShownPopupMessages.map(Number);
};

const PopupMessage = () => {
  const [visible, setVisible] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const messageId = query.has("popup_message")
    ? Number(query.get("popup_message"))
    : "active";

  const { data: popupMessageData, isSuccess: popupMessageIsSuccess } =
    useGetPopupMessage(messageId);

  const { data: settingsData } = useGetSettingsQuery({
    enabled: popupMessageIsSuccess,
  });

  const popupMessage = popupMessageIsSuccess
    ? popupMessageData.popup_message
    : null;

  useEffect(() => {
    if (popupMessageIsSuccess && popupMessageData.popup_message !== null) {
      const shownPopupMessages = getShownPopupMessages();

      setVisible(
        messageId !== "active" ||
          !shownPopupMessages.includes(popupMessageData.popup_message.id)
      );
    }
  }, [popupMessageIsSuccess]);

  if (popupMessage === null || !visible) {
    return null;
  }

  const settings = settingsData?.settings;

  const handleClose = () => {
    if (popupMessage !== null && messageId === "active") {
      const shownPopupMessages = getShownPopupMessages();
      if (!shownPopupMessages.includes(popupMessage.id)) {
        shownPopupMessages.push(popupMessage.id);
        Cookies.set(COOKIE_NAME, JSON.stringify(shownPopupMessages), {
          expires: 365,
        });
      }
    }
    setVisible(false);
  };

  return (
    <div className="popupMessageRoot">
      <div className="popupMessageContainer">
        <div className="popupMessageWrapper">
          <div className="popupMessage">
            <div className="popupMessage-header">
              <span className="popupMessage-logo" />

              <button
                className="popupMessage-close"
                type="button"
                onClick={handleClose}
              >
                <span className="popupMessage-closeIcon" />
              </button>
              {/* <div className="popupMessage-icons">
              {settings?.site_phone ? (
                <div className="popupMessage-link whatsapp">
                  <a
                    href={`https://wa.me/${settings.site_phone.replace(
                      /\s/,
                      ""
                    )}`}
                  >
                    {settings.site_phone}
                  </a>
                </div>
              ) : null}
            </div> */}
            </div>
            <div className="popupMessage-content">
              <Content>{popupMessage.content}</Content>

              {popupMessage.button_url !== null &&
                popupMessage.button_text !== null && (
                  <div className="popupMessage-actions">
                    <a
                      onClick={handleClose}
                      href={popupMessage.button_url}
                      className="button "
                    >
                      {popupMessage.button_text}
                    </a>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PopupMessageRoot = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <PopupMessage />
    </QueryClientProvider>
  );
};
export default PopupMessageRoot;
