import { Link, useSearchParams } from "react-router-dom";
import BasketType from "../../types/BasketType";
import "./BasketActions.scss";
import GooglePayButton from "../NewOrder/GooglePayButton";
import PaypalButton from "../NewOrder/PaypalButton";

interface BasketActionsProps {
  basket: BasketType;
}

const BasketActions = ({ basket }: BasketActionsProps) => {
  const [searchParams] = useSearchParams();

  if (basket.items_count === 0) {
    return null;
  }

  const isGooglePayEnabled = searchParams.has("google_pay")

  return (
    <div className="basketActions">
      {isGooglePayEnabled && (<GooglePayButton basket={basket} />)}
      <PaypalButton basket={basket} />
      <Link to="/checkout" className="btn-basket">
        Checkout
      </Link>
    </div>
  );
};
export default BasketActions;
