import ProducerType from "../../types/ProducerType";
import ProducerTile from "./ProducerTile";

import "./ProducersList.scss";

interface ProducersListProps {
  producers: ProducerType[];
}

const ProducersList = ({ producers }: ProducersListProps) => {
  return (
    <div className="producersList">
      {producers.map((producer) => (
        <ProducerTile key={producer.id} producer={producer} />
      ))}
    </div>
  );
};
export default ProducersList;
