import ContactChannels from "../components/Contact/ContactChannels";
import ContactForm from "../components/Contact/ContactForm";
import LoadingContainer from "../components/LoadingContainer";
import "./ContactPage.scss";

const ContactPage = () => {
  return (
    <LoadingContainer
      loading={false}
      id="contactPage"
      breadcrumbsProps={{
        items: [{ label: "Contact" }],
      }}
    >
      <div className="contactPage-text">
        Hello style and beauty lovers! Do you have any questions or need advice?
        <br />
        Our friendly Customer Service team is always happy to assist you.
      </div>

      <div className="contactPageColumns">
        <ContactForm />
        <ContactChannels />
      </div>
    </LoadingContainer>
  );
};

export default ContactPage;
