import { InputHTMLAttributes } from "react";

import classNames from "classnames";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import FormField from "./FormField";
import "./TextInput.scss";

type TextAreaProps<TFieldValues extends FieldValues = FieldValues> =
  InputHTMLAttributes<HTMLTextAreaElement> & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
    label: string;
  };

const TextArea = <TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  label,
  ...props
}: TextAreaProps<TFieldValues>) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ control, name });

  const inputId = props.id || name;

  return (
    <FormField
      error={error?.message}
      inputId={inputId}
      label={label}
      required={props.required}
    >
      <textarea
        id={inputId}
        {...props}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        className={classNames("textArea", props.className)}
      />
    </FormField>
  );
};
export default TextArea;
