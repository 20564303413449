import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetMyEmployeeProfileQuery } from "../../api/authApi";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import "./EditLink.scss";

interface EditLinkProps {
  url: string;
}

const EditLink = ({ url }: EditLinkProps) => {
  const { currentEmployeeIsLogged } = useGetMyEmployeeProfileQuery();

  if (!currentEmployeeIsLogged) {
    return null;
  }
  return (
    <div className="editLink">
      <a href={url}>
        <FontAwesomeIcon icon={faEdit} />
        edit
      </a>
    </div>
  );
};
export default EditLink;
