import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import BasketType from "../../types/BasketType";
import { confirmPaypalOrder, initPaypalOrder } from "../../api/ordersApi";

import {
  OnApproveData,
  OnApproveActions,
} from "@paypal/paypal-js/types/components/buttons";
import { toast } from "react-toastify";
import ecommerceGA from "../../utils/ecommerceGA";

const paypalClientId = process.env.PAYPAL_CLIENT_ID;

interface PaypalButtonProps {
  basket: BasketType;
}

const initialOptions = {
  clientId: paypalClientId,
  currency: "GBP",
  enableFunding: "",
  disableFunding: "paylater,venmo,card",
};

const PaypalButton = ({ basket }: PaypalButtonProps) => {
  const handleCreateOrder = async () => {
    const response = await initPaypalOrder();

    if (response.id) {
      return response.id;
    } else {
      toast.error("Paypal order creation failed");
      throw new Error("Paypal order creation failed");
    }
  };

  const handleApproveOrder = async (
    data: OnApproveData,
    actions: OnApproveActions
  ) => {
    const { order, payment_url } = await confirmPaypalOrder({
      orderId: data.orderID,
    });
    ecommerceGA.purchase(order);
    ecommerceGA.addShippingInfo(order);
    ecommerceGA.addPaymentInfo(order, "Paypal");
    if (payment_url !== undefined) {
      window.history.replaceState(
        {},
        document.title,
        `/my-order/${order.id}/${order.secret_code}`
      );
      window.location.href = payment_url;
    } else {
      window.location.href = `/my-order/${order.id}/${order.secret_code}`;
    }
    try {
    } catch (error) {
      toast.error("Paypal order approval failed");
      throw new Error("Paypal order approval failed");
    }
  };

  return (
    <div className="paypalButton">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
            height: 55,
          }}
          fundingSource="paypal"
          createOrder={handleCreateOrder}
          onApprove={handleApproveOrder}
        />
      </PayPalScriptProvider>
    </div>
  );
};
export default PaypalButton;
