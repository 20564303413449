import { useGetCategoryBestsellersQuery } from "../../api/categoriesApi";
import CategoryType from "../../types/CategoryType";
import ProductsList from "../Products/ProductsList";

import "./CategoryBestsellers.scss";

interface CategoryBestsellersProps {
  category: CategoryType;
}

const CategoryBestsellers = ({ category }: CategoryBestsellersProps) => {
  const { data: bestsellersData, isLoading: bestsellersIsLoading } =
    useGetCategoryBestsellersQuery(category);

  if (bestsellersIsLoading) {
    return null;
  }

  return (
    <div className="categoryBestsellers">
      <h2 className="categoryBestsellers-heading">
        Most popular <span>in {category.name}</span>
      </h2>

      <ProductsList products={bestsellersData?.products} />
    </div>
  );
};
export default CategoryBestsellers;
