import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Checkbox from "../FormControls/CheckBox";
import SubmitButton from "../FormControls/SubmitButton";
import TextInput from "../FormControls/TextInput";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  UpdateAccountParams,
  useGetMyProfileQuery,
  useUpdateAccountMutation,
} from "../../api/authApi";
import Loader from "../Loader";

import "./EditAccountForm.scss";

interface EditAccountFormProps {
  type: "billing" | "delivery";
}

const EditAccountForm = ({ type }: EditAccountFormProps) => {
  const { control, handleSubmit, setError, reset } =
    useForm<UpdateAccountParams>({
      defaultValues: {
        full_name: "",
        company_name: "",
        company_number: "",
        address_line1: "",
        address_line2: "",
        city: "",
        zipcode: "",
        phone_number: "",
        email: "",
        custom_delivery_address: false,
        delivery_full_name: "",
        delivery_company_name: "",
        delivery_company_number: "",
        delivery_address_line1: "",
        delivery_address_line2: "",
        delivery_city: "",
        delivery_zipcode: "",
        delivery_phone_number: "",
        delivery_email: "",
      },
    });
  const navigate = useNavigate();

  // const hasCustomDeliveryAddress = useWatch({
  //   control,
  //   name: "custom_delivery_address",
  // });

  const { currentCustomer } = useGetMyProfileQuery();
  const { mutate: updateAccount, isPending: updateAccountIsPending } =
    useUpdateAccountMutation({
      onSuccess: () => {
        toast.success("Your profile has been successfully updated");
        navigate("/my-account");
      },
      onError: (error) => {
        if (error.statusCode === 422) {
          Object.entries(error.errors || {}).forEach(([name, message]) => {
            setError(name as keyof UpdateAccountParams, {
              message: Array.isArray(message) ? message.join(", ") : message,
            });
          });
        }
      },
    });

  useEffect(() => {
    if (currentCustomer !== undefined) {
      reset({
        full_name: currentCustomer.full_name ?? "",
        company_name: currentCustomer.company_name ?? "",
        company_number: currentCustomer.company_number ?? "",
        address_line1: currentCustomer.address_line1 ?? "",
        address_line2: currentCustomer.address_line2 ?? "",
        city: currentCustomer.city ?? "",
        zipcode: currentCustomer.zipcode ?? "",
        phone_number: currentCustomer.phone_number ?? "",
        email: currentCustomer.email ?? "",
        custom_delivery_address:
          currentCustomer.custom_delivery_address || type === "delivery",
        delivery_full_name: currentCustomer.delivery_full_name ?? "",
        delivery_company_name: currentCustomer.delivery_company_name ?? "",
        delivery_company_number: currentCustomer.delivery_company_number ?? "",
        delivery_address_line1: currentCustomer.delivery_address_line1 ?? "",
        delivery_address_line2: currentCustomer.delivery_address_line2 ?? "",
        delivery_city: currentCustomer.delivery_city ?? "",
        delivery_zipcode: currentCustomer.delivery_zipcode ?? "",
        delivery_phone_number: currentCustomer.delivery_phone_number ?? "",
        delivery_email: currentCustomer.delivery_email ?? "",
      });
    }
  }, [currentCustomer, type, reset]);

  const onSubmit = (data: UpdateAccountParams) => {
    updateAccount(data);
  };
  return (
    <form className="customerForm" onSubmit={handleSubmit(onSubmit)}>
      <Loader loading={updateAccountIsPending} />

      {type === "billing" && (
        <div className="customerForm-section">
          <h3 className="customerForm-heading">Billing details</h3>

          <TextInput
            label="Name and Surname"
            required
            name="full_name"
            control={control}
          />

          <TextInput label="Company" name="company_name" control={control} />

          <TextInput
            label="Company number"
            name="company_number"
            control={control}
          />

          <TextInput
            label="Address line 1"
            required
            name="address_line1"
            control={control}
          />

          <TextInput
            label="Address line 2"
            name="address_line2"
            control={control}
          />

          <div className="customerForm-locationInputs">
            <TextInput
              label="Post Code"
              required
              name="zipcode"
              control={control}
            />
            <TextInput label="City" required name="city" control={control} />
          </div>

          <TextInput
            label="Phone number"
            required
            name="phone_number"
            control={control}
          />
          <TextInput
            label="E-mail"
            type="email"
            required
            name="email"
            control={control}
          />

          <Checkbox
            label="Different delivery address?"
            name="custom_delivery_address"
            control={control}
          />
        </div>
      )}

      {type === "delivery" && (
        <div className="customerForm-section">
          <h3 className="customerForm-heading">Delivery details</h3>

          <TextInput
            label="Name and Surname"
            required
            name="delivery_full_name"
            control={control}
          />

          <TextInput
            label="Company"
            name="delivery_company_name"
            control={control}
          />

          <TextInput
            label="Company number"
            name="delivery_company_number"
            control={control}
          />

          <TextInput
            label="Address line 1"
            required
            name="delivery_address_line1"
            control={control}
          />

          <TextInput
            label="Address line 2"
            name="delivery_address_line2"
            control={control}
          />

          <div className="customerForm-locationInputs">
            <TextInput
              label="Post Code"
              required
              name="delivery_zipcode"
              control={control}
            />
            <TextInput
              label="City"
              required
              name="delivery_city"
              control={control}
            />
          </div>

          <TextInput
            label="Phone number"
            name="delivery_phone_number"
            control={control}
          />
          <TextInput
            label="E-mail"
            type="email"
            name="delivery_email"
            control={control}
          />
        </div>
      )}

      <SubmitButton disabled={updateAccountIsPending}>Change</SubmitButton>
    </form>
  );
};
export default EditAccountForm;
