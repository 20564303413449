import { useForm } from "react-hook-form";
import SubmitButton from "../FormControls/SubmitButton";
import TextInput from "../FormControls/TextInput";

import Heading from "../Heading";
import { ResetPasswordParams } from "../../api/resetPasswordApi";

import "./AuthForm.scss";

interface ResetPasswordFormProps {
  onSubmit: (data: ResetPasswordParams) => void;
}

const ResetPasswordForm = ({ onSubmit }: ResetPasswordFormProps) => {
  const { control, handleSubmit } = useForm<ResetPasswordParams>({
    defaultValues: {
      email: "",
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="authForm">
      <Heading>RESET YOUR PASSWORD</Heading>

      <TextInput
        label="Email address"
        type="email"
        required
        autoFocus
        name="email"
        control={control}
      />

      <div className="authForm-actions">
        <SubmitButton className="authForm-submit">Submit</SubmitButton>
      </div>
    </form>
  );
};
export default ResetPasswordForm;
