import { useParams } from "react-router-dom";
import { useGetArticleQuery } from "../api/articlesApi";
import ArticleImage from "../components/Articles/ArticleImage";
import RelatedProducts from "../components/Articles/RelatedProducts";
import Content from "../components/Content";
import Heading from "../components/Heading";
import LoadingContainer from "../components/LoadingContainer";
import EditLink from "../components/Utils/EditLink";
import MetaTags from "../components/MetaTags";

const ArticlePage = () => {
  const { slug } = useParams();

  const { data: articleData, isSuccess: articleIsSuccess } = useGetArticleQuery(
    slug ?? ""
  );

  return (
    <LoadingContainer
      id="articlePage"
      loading={!articleIsSuccess}
      breadcrumbsProps={{
        items: [
          { label: "Articles", url: "/articles" },
          articleData ? { label: articleData.article.title } : undefined,
        ],
      }}
    >
      {articleIsSuccess && (
        <>
          <MetaTags
            title={articleData.article.meta_title || articleData.article.title}
            description={articleData.article.meta_description ?? undefined}
          />
          <EditLink url={`/admin/articles/${articleData.article.id}/edit`} />
          <Heading>{articleData.article.title}</Heading>
          <ArticleImage article={articleData.article} />
          <Content>{articleData.article.content}</Content>
          <RelatedProducts article={articleData.article} />
        </>
      )}
    </LoadingContainer>
  );
};
export default ArticlePage;
