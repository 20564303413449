import { useGetArticleProductsQuery } from "../../api/articlesApi";
import type ArticleType from "../../types/ArticleType";
import ProductsList from "../Products/ProductsList";
import Subheading from "../Subheading";

interface RelatedProductsProps {
  article: ArticleType;
}

const RelatedProducts = ({ article }: RelatedProductsProps) => {
  const { data: productsData } = useGetArticleProductsQuery(article.slug);

  return (
    <div className="articleProducts">
      <Subheading>
        Products related to <br />
        the article
      </Subheading>

      <ProductsList products={productsData?.products} />
    </div>
  );
};
export default RelatedProducts;
