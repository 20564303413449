import React, { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import "./SortDropdown.scss";
import { GetProductsParams } from "../../api/productsApi";
import { Link, useNavigate } from "react-router-dom";
import { catalogUrl } from "../../utils/catalogUtils";
import qs from "qs";
import { getSortParam } from "../../utils/getParam";

interface SortDropdownProps {
  params: GetProductsParams;
}

const SortDropdown = ({ params }: SortDropdownProps) => {
  const [isOpen, setOpen] = useState(false);
  const options = [
    // { value: "id_desc", label: "Newest" },
    { value: "price_asc", label: "Price low to high" },
    { value: "price_desc", label: "Price high to low" },
    { value: "popularity", label: "Bestsellers" },
  ];

  const sort = getSortParam();
  const navigate = useNavigate();

  const handleChangeSort = (value: string) => {
    const extraParams = value === "id_desc" ? {} : { sort: value };
    navigate(`${catalogUrl(params)}?${qs.stringify(extraParams)}`);
  };

  return (
    <div className="sortDropdown">
      <DropdownMenu.Root open={isOpen} onOpenChange={setOpen}>
        <DropdownMenu.Trigger asChild>
          <div className="sortDropdown-button">
            <span className="sortDropdown-buttonLabel">SORT</span>
            <span className="sortDropdown-buttonIndicator"></span>
          </div>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content className="sortDropdown-content" align="start">
            {options.map((option) => (
              <DropdownMenu.Item
                key={option.value}
                className="sortDropdown-item"
                onSelect={() => {
                  handleChangeSort(option.value);
                }}
              >
                {option.label}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

export default SortDropdown;
