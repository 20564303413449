import "./OrderConfirmation.scss";

const OrderConfirmation = () => {
  return (
    <div className="orderConfirmation">
      <h3 className="orderConfirmation-heading">
        Thank you for your purchase.
      </h3>

      <p className="orderConfirmation-text">
        We have sent you a confirmation email with the order details.
        <br />
        Please check your mailbox.
      </p>
    </div>
  );
};
export default OrderConfirmation;
