import ApiError from "./ApiError";

const apiPrefix = "/api";

const fetchFromApi = async <ResponseType extends any = any>(
  url: string,
  method: "GET" | "POST" | "DELETE" = "GET",
  body = {}
): Promise<ResponseType> => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const response = await fetch(`${apiPrefix}${url}`, {
    headers,
    method,
    body: method === "GET" ? undefined : JSON.stringify(body),
  });

  if (!response.ok) {
    const statusCode = response.status;
    const errorResponse = await response.json();

    return await Promise.reject(new ApiError({ statusCode, ...errorResponse }));
  }
  return response.json();
};

export default fetchFromApi;
