import "./BasketKlarnaMessage.scss";

const BasketKlarnaMessage = () => {
  return (
    <div className="basketKlarnaMessage">
      If you wish to pay using <b>Klarna</b>, please proceed through the
      checkout process and select <b>Klarna</b> as your payment method at the
      final step.
    </div>
  );
};
export default BasketKlarnaMessage;
