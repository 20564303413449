import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Container from "../Container";
import EditAccountPage from "../../pages/EditAccountPage";
import LoginPage from "../../pages/LoginPage";
import AccountPage from "../../pages/AccountPage";
import ContactPage from "../../pages/ContactPage";
import WishListPage from "../../pages/WishListPage";
import BasketPage from "../../pages/BasketPage";
import NewOrderPage from "../../pages/NewOrderPage";
import ArticlesPage from "../../pages/ArticlesPage";
import ProducersPage from "../../pages/ProducersPage";
import ProducerPage from "../../pages/ProducerPage";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import ChangePasswordPage from "../../pages/ChangePasswordPage";
import OrderPage from "../../pages/OrderPage";
import ArticlePage from "../../pages/ArticlePage";
import SearchResultsPage from "../../pages/SearchResultsPage";
import CatalogPage from "../../pages/CatalogPage";
import TextPage from "../../pages/TextPage";
import SeriesPage from "../../pages/SeriesPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Container />,
    children: [
      {
        path: "/search",
        element: <SearchResultsPage />,
      },
      {
        path: "/my-settings/:type-address",
        element: <EditAccountPage />,
      },
      {
        path: "/my-account",
        element: <AccountPage />,
      },
      {
        path: "/catalog/:category/*",
        element: <CatalogPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
      {
        path: "/wishlist",
        element: <WishListPage />,
      },
      {
        path: "/basket",
        element: <BasketPage />,
      },
      {
        path: "/checkout",
        element: <NewOrderPage />,
      },
      {
        path: "/my-order/:id/:secret",
        element: <OrderPage />,
      },
      {
        path: "/articles",
        element: <ArticlesPage />,
      },
      {
        path: "/articles/:slug",
        element: <ArticlePage />,
      },
      {
        path: "/brands",
        element: <ProducersPage />,
      },
      {
        path: "/brands/:slug",
        element: <ProducerPage />,
      },
      {
        path: "/series/:slug",
        element: <SeriesPage />,
      },
      {
        path: "/s/:slug",
        element: <TextPage />,
      },
      {
        path: "/customers/password/new",
        element: <ResetPasswordPage />,
      },
      {
        path: "/customers/password/edit",
        element: <ChangePasswordPage />,
      },
    ],
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};
export default AppRouter;
