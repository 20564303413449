import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArticleType from "../../types/ArticleType";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

import "./ArticleItem.scss";
import { Link } from "react-router-dom";

interface ArticleItemProps {
  article: ArticleType;
  className?: string;
}

const ArticleItem = ({ article, className }: ArticleItemProps) => {
  return (
    <div className={classNames("articleItem", className)}>
      <div className="articleItem-image">
        {article.medium_image_path !== null && (
          <img src={article.medium_image_path} alt={article.title} />
        )}
      </div>
      <div className="articleItem-details">
        <div className="articleItem-category">
          {article.categories[0]?.name}
        </div>
        <h3 className="articleItem-title">
          <a href={`/articles/${article.slug}`}>{article.title}</a>
        </h3>
        <div
          className="articleItem-description"
          dangerouslySetInnerHTML={{ __html: article.description_short }}
        />
        <div className="articleItem-actions">
          <Link to={`/articles/${article.slug}`} className="button">
            Read more
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ArticleItem;
