import { useEffect } from "react";

import { useGetBasketQuery } from "../api/basketApi";
import BasketActions from "../components/Basket/BasketActions";
import BasketItems from "../components/Basket/BasketItems";
import BasketKlarnaMessage from "../components/Basket/BasketKlarnaMessage";
import BasketSummary from "../components/Basket/BasketSummary";
import LoadingContainer from "../components/LoadingContainer";

import ecommerceGA from "../utils/ecommerceGA";
import "./BasketPage.scss";

const BasketPage = () => {
  const { data: basketData, isSuccess: basketIsSuccess } = useGetBasketQuery();

  useEffect(() => {
    if (basketIsSuccess) {
      ecommerceGA.viewCart(basketData.basket);
    }
  }, [basketIsSuccess]);

  return (
    <LoadingContainer
      loading={!basketIsSuccess}
      id="basketPage"
      breadcrumbsProps={{ items: [{ label: "Basket", url: "/basket" }] }}
    >
      {basketIsSuccess && (
        <>
          <div className="basketColumns">
            <BasketItems basket={basketData.basket} />

            <BasketSummary basket={basketData.basket} />
          </div>

          <div className="basketColumns">
            <div />
            <div>
              <BasketActions basket={basketData.basket} />

              <BasketKlarnaMessage />
            </div>
          </div>
        </>
      )}
    </LoadingContainer>
  );
};

export default BasketPage;
