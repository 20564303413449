import classNames from "classnames";
import ErrorMessage from "./ErrorMessage";

import "./FormField.scss";

interface FormFieldProps {
  label?: string;
  error?: string;
  inputId: string;
  required?: boolean;
  children: React.ReactNode;
  className?: string;
}

const FormField = ({
  label,
  error,
  inputId,
  required = false,
  children,
  className,
}: FormFieldProps) => {
  return (
    <div className={classNames("formField", className)}>
      {label ? (
        <label htmlFor={inputId}>
          {label}
          {required ? <abbr title="required">*</abbr> : null}
        </label>
      ) : null}
      <div className="formField-input">{children}</div>
      <ErrorMessage error={error} />
    </div>
  );
};
export default FormField;
