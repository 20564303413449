import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./Utils/ScrollToTop";
import { Outlet, useLocation } from "react-router-dom";

const queryClient = new QueryClient();

const Container = () => {
  const { pathname } = useLocation();

  const isCatalogPage = pathname.startsWith("/catalog/");
  return (
    <QueryClientProvider client={queryClient}>
      <div className="container">
        <Outlet />
      </div>

      {__DEV__ && <ReactQueryDevtools initialIsOpen={false} />}
      <ToastContainer />
      {!isCatalogPage && <ScrollToTop />}
    </QueryClientProvider>
  );
};
export default Container;
