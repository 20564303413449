import classNames from "classnames";
import ProductType from "../../types/ProductType";
import Product from "./Product";

import "./ProductsList.scss";

interface ProductsListProps {
  products: ProductType[] | undefined;
  className?: string;
}

const ProductsList = ({ products, className }: ProductsListProps) => {
  return (
    <div className={classNames("productsList", className)}>
      {products?.map((product) => (
        <Product key={product.id} product={product} />
      ))}
    </div>
  );
};
export default ProductsList;
