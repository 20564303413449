import OrderItemType from "../../types/OrderItemType";
import formatPrice from "../../utils/formatPrice";

import "./OrderItem.scss";

interface OrderItemProps {
  orderItem: OrderItemType;
}

const OrderItem = ({ orderItem }: OrderItemProps) => {
  return (
    <div className="orderItem">
      <div className="orderItem-image">
        {orderItem.product.image_path !== null ? (
          <img
            src={orderItem.product.image_path}
            alt={orderItem.product.name}
          />
        ) : null}
      </div>
      <div className="orderItem-name">
        <a href={`/product/${orderItem.product.slug}`}>
          {orderItem.product.name}
        </a>
      </div>
      <div className="orderItem-price">
        {formatPrice(orderItem.unit_price * orderItem.quantity)}
      </div>
    </div>
  );
};
export default OrderItem;
