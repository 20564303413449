import { useQuery } from "@tanstack/react-query";
import fetchFromApi from "./fetchFromApi";
import ArticleType from "../types/ArticleType";
import ApiError from "./ApiError";
import ProductType from "../types/ProductType";
import PaginatedResponse from "./PaginatedResponse";

const ARTICLES_QUERY_KEY = "articles";
const ARTICLE_QUERY_KEY = "article";

interface ArticlesResponse {
  articles: ArticleType[];
}

interface ArticleResponse {
  article: ArticleType;
}

interface ArticleProductsResponse {
  products: ProductType[];
}

export const useGetArticlesQuery = (page = 1) => {
  return useQuery<unknown, ApiError, PaginatedResponse<ArticlesResponse>>({
    queryKey: [ARTICLES_QUERY_KEY, { page }],
    queryFn: () => fetchFromApi(`/articles?page=${page}`),
  });
};

export const useGetArticleQuery = (articleId: string) => {
  return useQuery<unknown, ApiError, ArticleResponse>({
    queryKey: [ARTICLE_QUERY_KEY, articleId],
    queryFn: () => fetchFromApi(`/articles/${articleId}`),
  });
};

export const useGetArticleProductsQuery = (articleId: string) => {
  return useQuery<unknown, ApiError, ArticleProductsResponse>({
    queryKey: [ARTICLE_QUERY_KEY, articleId, "products"],
    queryFn: () => fetchFromApi(`/articles/${articleId}/products`),
  });
};
