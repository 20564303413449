import "./Subheading.scss";

interface SubheadingProps {
  children: React.ReactNode;
}

const Subheading = ({ children }: SubheadingProps) => {
  return <h3 className="pageSubheading">{children}</h3>;
};
export default Subheading;
