import ProductType from "../../../types/ProductType";

import "./ProductTags.scss";

interface ProductTagsProps {
  product: ProductType;
}

const ProductTags = ({ product }: ProductTagsProps) => {
  if (product.tags.length === 0) return null;

  return (
    <div className="productTags">
      {product.tags.map((tag, index) => (
        <span key={index}>{tag}</span>
      ))}
    </div>
  );
};
export default ProductTags;
