import "./Breadcrumbs.scss";

interface BreadcrumbItemType {
  label: string;
  url?: string;
}
export interface BreadcrumbsProps {
  items: (BreadcrumbItemType | undefined)[];
}

const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  const filteredItems = items.filter(
    (item) => item !== undefined
  ) as BreadcrumbItemType[];
  return (
    <ul className="breadcrumbs">
      <li className="breadcrumbsItem">
        <a href="/">RedDamson.co.uk</a>
      </li>
      {filteredItems.map((item, index) => (
        <li key={index} className="breadcrumbsItem">
          {item.url !== undefined ? (
            <a href={item.url}>{item.label}</a>
          ) : (
            <span>{item.label}</span>
          )}
        </li>
      ))}
    </ul>
  );
};
export default Breadcrumbs;
