import { useGetSubpageDescriptionQuery } from "../api/subpageDescriptionsApi";
import Content from "./Content";
import "./SubpageDescription.scss";

interface SubpageDescriptionProps {
  defaultTitle?: string | null;
  defaultImage?: string | null;
  defaultDescription?: string | null;
}
const SubpageDescription = ({
  defaultTitle,
  defaultImage,
  defaultDescription,
}: SubpageDescriptionProps) => {
  const {
    data: subpageDescriptionData,
    isLoading: subpageDescriptionIsLoading,
  } = useGetSubpageDescriptionQuery(window.location.pathname);

  if (subpageDescriptionIsLoading) {
    return null;
  }

  if (!defaultTitle && !defaultImage && !defaultDescription) {
    return null;
  }
  const subpageDescription = subpageDescriptionData?.subpage_description;

  return (
    <div className="subpageDescription">
      {subpageDescription?.title ?? defaultTitle ? (
        <h1 className="subpageDescription-title">
          {subpageDescription?.title ?? defaultTitle}
        </h1>
      ) : null}

      <div className="subpageDescription-content">
        {defaultImage ? (
          <div className="subpageDescription-image">
            <img src={defaultImage} alt="" />
          </div>
        ) : null}
        <Content className="subpageDescription-text">
          {subpageDescription?.description ?? defaultDescription ?? ""}
        </Content>
      </div>
    </div>
  );
};
export default SubpageDescription;
