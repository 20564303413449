import ReactDOM from "react-dom/client";

import "react-toastify/dist/ReactToastify.css";
import AppRouter from "./components/Navigation/AppRouter";
import "./styles/react_app.scss";
import PopupMessage from "./components/PopupMessage";

document.addEventListener("DOMContentLoaded", () => {
  const appNode = document.getElementById("app_root") as HTMLElement;
  if (appNode) {
    const appRoot = ReactDOM.createRoot(appNode);
    appRoot.render(<AppRouter />);
  }

  const popupMessageNode = document.getElementById(
    "popup_message_root"
  ) as HTMLElement;
  if (popupMessageNode) {
    const popupMessageRoot = ReactDOM.createRoot(popupMessageNode);
    popupMessageRoot.render(<PopupMessage />);
  }
});
