import { useGetWishlistQuery } from "../api/wishlistApi";
import Heading from "../components/Heading";
import LoadingContainer from "../components/LoadingContainer";
import WishListItems from "../components/WishList/WishListItems";

// import "./WishListPage.scss";

const WishListPage = () => {
  const query = new URLSearchParams(location.search);
  const page = (query.get("page") ?? 1) as number;

  const { data: wishListData, isSuccess: wishListIsSuccess } =
    useGetWishlistQuery(page);

  return (
    <LoadingContainer
      loading={!wishListIsSuccess}
      id="wish"
      breadcrumbsProps={{
        items: [{ label: "Wish List" }],
      }}
    >
      <Heading>Wish list</Heading>

      <WishListItems items={wishListData?.products ?? []} />
    </LoadingContainer>
  );
};

export default WishListPage;
