import ChangePasswordForm from "../components/Auth/ChangePasswordForm";
import LoadingContainer from "../components/LoadingContainer";

import "./ChangePasswordPage.scss";

const ChangePasswordPage = () => {
  return (
    <LoadingContainer
      id="changePasswordPage"
      loading={false}
      breadcrumbsProps={{ items: [{ label: "Reset password" }] }}
    >
      <div className="changePasswordPageContainer">
        <ChangePasswordForm />
      </div>
    </LoadingContainer>
  );
};

export default ChangePasswordPage;
