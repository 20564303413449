import type ArticleType from "../../types/ArticleType";

import "./ArticleImage.scss";

interface ArticleImageProps {
  article: ArticleType;
}

const ArticleImage = ({ article }: ArticleImageProps) => {
  if (article.image_path == null) {
    return null;
  }
  return (
    <div className="articleImage">
      <img src={article.image_path} alt={article.title} />
    </div>
  );
};
export default ArticleImage;
