import ArticleType from "../../types/ArticleType";
import ArticleItem from "./ArticleItem";

import "./ArticlesList.scss";

interface ArticlesListProps {
  articles: ArticleType[];
}

const ArticlesList = ({ articles }: ArticlesListProps) => {
  return (
    <div className="articlesList">
      {articles.slice(0, 4).map((article, index) => (
        <ArticleItem
          key={article.id}
          article={article}
          className={`articleItem-${index + 1}`}
        />
      ))}

      <div className="articlesList-grid">
        {articles.slice(5, 8).map((article) => (
          <ArticleItem
            key={article.id}
            article={article}
            className={`articleItem-grid`}
          />
        ))}
      </div>

      {articles.slice(8, 13).map((article, index) => (
        <ArticleItem
          key={article.id}
          article={article}
          className={`articleItem-${index + 5}`}
        />
      ))}
    </div>
  );
};
export default ArticlesList;
