import { HTMLAttributes } from "react";
import Loader from "./Loader";
import classNames from "classnames";
import Breadcrumbs, { BreadcrumbsProps } from "./Breadcrumbs";

interface LoadingContainerProps extends HTMLAttributes<HTMLDivElement> {
  loading?: boolean;
  breadcrumbsProps?: BreadcrumbsProps;
}

const LoadingContainer = ({
  loading = false,
  children,
  breadcrumbsProps,
  ...props
}: LoadingContainerProps) => {
  return (
    <div {...props} className={classNames("loadingContainer", props.className)}>
      {breadcrumbsProps !== undefined ? (
        <Breadcrumbs {...breadcrumbsProps} />
      ) : null}

      {loading && <Loader />}
      {children}
    </div>
  );
};
export default LoadingContainer;
