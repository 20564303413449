import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ProductType from "../types/ProductType";
import ApiError from "./ApiError";
import PaginatedResponse from "./PaginatedResponse";
import fetchFromApi from "./fetchFromApi";
import {
  PRODUCTS_FILTERS_QUERY_KEY,
  PRODUCTS_QUERY_KEY,
  SEARCH_RESULTS_QUERY_KEY,
} from "./productsApi";

const MY_WISHLIST_QUERY_KEY = ["my-wish-list"];

interface WishlistProductsResponse {
  products: ProductType[];
}
interface WishlistProductResponse {
  product: ProductType;
}

export const useGetWishlistQuery = (page = 1) => {
  return useQuery<
    unknown,
    ApiError,
    PaginatedResponse<WishlistProductsResponse>
  >({
    queryKey: [...MY_WISHLIST_QUERY_KEY, { page }],
    queryFn: () => fetchFromApi(`/wishlist?page=${page}`),
  });
};

export const useAddProductToWishlistMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<WishlistProductResponse, ApiError, { productId: number }>({
    mutationFn: ({ productId }) =>
      fetchFromApi(`/wishlist/${productId}`, "POST"),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [...MY_WISHLIST_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [PRODUCTS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [PRODUCTS_FILTERS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [SEARCH_RESULTS_QUERY_KEY] });

      if ("showBasketLayer" in window) {
        (
          window.showBasketLayer as (product: ProductType, text: string) => void
        )(data.product, "Item added to Wish List", "/wishlist");
      }
    },
  });
};

export const useRemoveProductFromWishlistMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<WishlistProductResponse, ApiError, { productId: number }>({
    mutationFn: ({ productId }) =>
      fetchFromApi(`/wishlist/${productId}`, "DELETE"),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [...MY_WISHLIST_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [PRODUCTS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [PRODUCTS_FILTERS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [SEARCH_RESULTS_QUERY_KEY] });

      if ("showBasketLayer" in window) {
        (
          window.showBasketLayer as (product: ProductType, text: string) => void
        )(data.product, "Remove from Wish List", "/wishlist");
      }
    },
  });
};
