import { useState } from "react";
import LoginForm from "../components/Auth/LoginForm";
import RegisterForm from "../components/Auth/RegisterForm";
import Breadcrumbs from "../components/Breadcrumbs";
import MetaTags from "../components/MetaTags";
import Loader from "../components/Loader";

import "./LoginPage.scss";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);

  const onSuccess = () => (window.location.href = "/");

  return (
    <div id="loginPage">
      <MetaTags title="Log in" />
      <Breadcrumbs items={[{ label: "Log in" }]} />

      <Loader loading={loading} />

      <div className="loginPageColumns">
        <LoginForm setLoading={setLoading} onSuccess={onSuccess} />
        <RegisterForm onSuccess={onSuccess} />
      </div>
    </div>
  );
};

export default LoginPage;
