import { useGetSeriesProductsQuery } from "../../api/seriesApi";
import type SeriesType from "../../types/SeriesType";
import Pagination from "../Pagination";
import ProductsList from "../Products/ProductsList";

interface SeriesProductsProps {
  series: SeriesType;
}

const SeriesProducts = ({ series }: SeriesProductsProps) => {
  const query = new URLSearchParams(location.search);
  const page = (query.get("page") ?? 1) as number;

  const { data: productsData } = useGetSeriesProductsQuery(series.slug, page);

  return (
    <div className="seriesProducts">
      <Pagination url={`/series/${series.slug}`} meta={productsData?.meta} />
      <ProductsList products={productsData?.products} />
      <Pagination url={`/series/${series.slug}`} meta={productsData?.meta} />
    </div>
  );
};
export default SeriesProducts;
