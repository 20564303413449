import { InputHTMLAttributes } from "react";

import classNames from "classnames";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import FormField from "./FormField";

import "./CheckBox.scss";

type CheckBoxProps<TFieldValues extends FieldValues = FieldValues> =
  InputHTMLAttributes<HTMLInputElement> & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
    label: string | React.ReactNode;
  };

const CheckBox = <TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  label,
  ...props
}: CheckBoxProps<TFieldValues>) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ control, name });

  const inputId = props.id || name;

  return (
    <FormField
      error={error?.message}
      inputId={inputId}
      required={props.required}
      className="formFieldCheckbox"
    >
      <input
        type="checkbox"
        {...props}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        id={inputId}
        name={name}
        className={classNames("checkBox", props.className)}
      />
      <label htmlFor={inputId} className="checkBoxLabel">
        {label}
        {props.required ? <abbr title="required">*</abbr> : null}
      </label>
    </FormField>
  );
};
export default CheckBox;
