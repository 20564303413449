import { useParams } from "react-router-dom";
import { useGetStaticPageQuery } from "../api/staticPagesApi";
import Content from "../components/Content";
import Heading from "../components/Heading";
import LoadingContainer from "../components/LoadingContainer";
import MetaTags from "../components/MetaTags";
import EditLink from "../components/Utils/EditLink";
import { useEffect, useRef } from "react";

import './TextPage.scss';

const TextPage = () => {
  const { slug } = useParams();

  const { data: staticPageData, isSuccess: staticPageIsSuccess } =
    useGetStaticPageQuery(slug ?? "");
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      divRef.current !== null &&
      (slug === "cookies" || slug == "privacy-policy-and-gdpr")
    ) {
      const script = document.createElement("script");

      script.src =
        "//report.cookie-script.com/r/6889bd3627a7d5be6d8617b4dcddb93f.js";
      script.async = true;
      script.setAttribute("data-cookiescriptreport", "report");

      divRef.current.appendChild(script);

      return () => {
        divRef.current?.removeChild(script);
      };
    }
  }, [divRef]);

  return (
    <LoadingContainer
      id="staticPagePage"
      loading={!staticPageIsSuccess}
      breadcrumbsProps={{
        items: [
          staticPageData
            ? { label: staticPageData.static_page.title }
            : undefined,
        ],
      }}
    >
      {staticPageIsSuccess && (
        <>
          <MetaTags
            title={
              staticPageData.static_page.meta_title ||
              staticPageData.static_page.title
            }
            description={
              staticPageData.static_page.meta_description ?? undefined
            }
            robots={staticPageData.static_page.robots_flags}
          />
          <EditLink
            url={`/admin/static_pages/${staticPageData.static_page.id}/edit`}
          />
          <Heading>{staticPageData.static_page.title}</Heading>

          <Content>{staticPageData.static_page.content}</Content>
        </>
      )}
      <div className="cookiesTable" ref={divRef}></div>
    </LoadingContainer>
  );
};
export default TextPage;
