import "./EmptyBasketMessage.scss";

const EmptyBasketMessage = () => {
  return (
    <div className="emptyBasketMessage">
      <h3 className="emptyBasketMessage-heading">Your basket is empty</h3>

      <p>
        Still not sure what you would like?
        <br />
        Keep browsing, we have many interesting pieces on offer.
      </p>
    </div>
  );
};
export default EmptyBasketMessage;
