import classNames from "classnames";
import "./Heading.scss";

interface HeadingProps {
  children: string | React.ReactNode | undefined;
  className?: string;
}

const Heading = ({ children, className }: HeadingProps) => {
  return <h2 className={classNames("pageHeading", className)}>{children}</h2>;
};
export default Heading;
