type LinkProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

const Link = ({ children, ...props }: LinkProps) => (
  <a {...props}>{children}</a>
);

export default Link;
