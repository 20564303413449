import { Params } from "react-router-dom";
import { useGetCategoryQuery } from "../api/categoriesApi";
import {
  GetProductsParams,
  useGetProductFiltersQuery,
  useGetProductsQuery,
} from "../api/productsApi";
import { useGetSeriesQuery } from "../api/seriesApi";
import { useGetSubpageDescriptionQuery } from "../api/subpageDescriptionsApi";
import { catalogUrl } from "../utils/catalogUtils";
import { getSortParam } from "../utils/getParam";
import qs from "qs";

const splitWithTail = (text: string, delim: string, count: number) => {
  const parts = text.split(delim);
  const tail = parts.slice(count).join(delim);
  const result = parts.slice(0, count);
  result.push(tail);
  return result;
};
const queryToFilters = (query: string) => {
  const queryParts = query.split("/");

  const results: Partial<GetProductsParams> & {
    features: GetProductsParams["features"];
  } = { features: {} };
  queryParts.forEach((part) => {
    const [name, value] = splitWithTail(part, "-", 1);
    const values = value.split(",");

    if (part.startsWith("pr-")) {
      const values = part.replace(/^pr-/, "").split(",");
      results["producer"] = values;
    } else if (part.startsWith("s-")) {
      const values = part.replace(/^s-/, "").split(",");
      results["series"] = values;
    } else {
      const [name, ...values] = part.split(",");
      results.features[name] = values;
    }
  });
  return results;
};

const useCatalog = (params: Readonly<Params<string>>, page: number) => {
  const category = params.category ?? "";
  const extraParams = queryToFilters(params["*"] ?? "");

  const apiParams = { category, ...extraParams };

  const sort = getSortParam();

  const { data: productsData, isLoading: productsIsLoading } =
    useGetProductsQuery(apiParams, page, sort);

  const singleSeries =
    apiParams.series !== undefined && apiParams.series.length === 1;

  const { data: productsFiltersData, isLoading: productsFiltersIsLoading } =
    useGetProductFiltersQuery(apiParams, page);
  const { data: categoryData, isLoading: categoryIsLoading } =
    useGetCategoryQuery(category);
  const {
    data: seriesData,
    isLoading: seriesIsLoading,
    isFetching: seriesIsFetching,
  } = useGetSeriesQuery(apiParams.series?.[0] ?? "", {
    enabled: singleSeries,
  });
  const { isLoading: subpageDescriptionIsLoading } =
    useGetSubpageDescriptionQuery(window.location.pathname);

  const isLoading =
    productsIsLoading ||
    productsFiltersIsLoading ||
    categoryIsLoading ||
    (seriesIsFetching && seriesIsLoading) ||
    subpageDescriptionIsLoading;

  const currentUrl = `${["", "catalog", category, params["*"]].join(
    "/"
  )}?${qs.stringify({ sort })}`;
  const catalogTitle =
    (singleSeries && seriesData?.series.header_title) ||
    categoryData?.category.name;
  const catalogDescription =
    (singleSeries && seriesData?.series.description) ||
    categoryData?.category.description;

  const catalogImage = singleSeries
    ? undefined
    : categoryData?.category.image_path;

  const breadcrumbs =
    categoryData?.category.parents.map((parent) => ({
      label: parent.name,
      url: catalogUrl(apiParams, "category", parent.slug),
    })) ?? [];

  return {
    products: productsData?.products,
    meta: productsData?.meta,
    filters: productsFiltersData,
    isLoading,
    title: catalogTitle,
    description: catalogDescription,
    image: catalogImage,
    breadcrumbs,
    currentUrl,
    category: categoryData?.category,
    extraParams,
    apiParams,
  };
};

export default useCatalog;
