import { useGetCategoryQuery } from "../../api/categoriesApi";
import { useGetSeriesQuery } from "../../api/seriesApi";
import Content from "../Content";

import "./CatalogDescription.scss";

interface CatalogDescriptionProps {
  params: {
    category: string;
    series?: string[];
  };
}

const CatalogDescription = ({ params }: CatalogDescriptionProps) => {
  const { data: categoryData } = useGetCategoryQuery(params.category);

  const { data: seriesData } = useGetSeriesQuery(params.series?.[0] ?? "", {
    enabled: params.series !== undefined && params.series.length === 1,
  });

  const description =
    seriesData?.series.description_long ||
    categoryData?.category.description_long;

  if (!description) {
    return null;
  }

  return <Content className="catalogDescription">{description}</Content>;
};
export default CatalogDescription;
