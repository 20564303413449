import BasketItemType from "../../types/BasketItemType";
import formatPrice from "../../utils/formatPrice";
import QuantityInput from "./QuantityInput";

import "./BasketItem.scss";

interface BasketItemProps {
  basketItem: BasketItemType;
  isChecked: boolean;
  onToggle: () => void;
}

const BasketItem = ({ basketItem, isChecked, onToggle }: BasketItemProps) => {
  return (
    <div className="basketItem">
      <div className="basketItem-action">
        <label>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => onToggle()}
          />
          <span />
        </label>
      </div>
      <div className="basketItem-image">
        {basketItem.product.medium_image_path !== null ? (
          <img
            src={basketItem.product.medium_image_path}
            alt={basketItem.product.name}
          />
        ) : null}
      </div>
      <div className="basketItem-name">
        <a href={`/product/${basketItem.product.slug}`}>
          {basketItem.product.name}
        </a>
      </div>
      <div className="basketItem-quantity">
        <QuantityInput
          product={basketItem.product}
          quantity={basketItem.quantity}
        />
      </div>
      <div className="basketItem-price">
        {formatPrice(basketItem.unit_price * basketItem.quantity)}
      </div>
    </div>
  );
};
export default BasketItem;
