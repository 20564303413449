import { useGoogleLogin } from "@react-oauth/google";

import "./GoogleSignInButton.scss";

interface GoogleSignInButtonProps {
  onSuccess: (token: string) => void;
}

const GoogleSignInButton = ({ onSuccess }: GoogleSignInButtonProps) => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      onSuccess(tokenResponse.access_token ?? "");
    },
  });

  return (
    <button
      type="button"
      onClick={() => login()}
      className="googleSignInButton"
    >
      <span>Log in with Google</span>
    </button>
  );
};
export default GoogleSignInButton;
