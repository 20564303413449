import { useParams } from "react-router-dom";
import { useGetProducerQuery } from "../api/producersApi";
import Content from "../components/Content";
import Heading from "../components/Heading";
import LoadingContainer from "../components/LoadingContainer";
import ProducerProducts from "../components/Producers/ProducerProducts";
import SubpageDescription from "../components/SubpageDescription";
import EditLink from "../components/Utils/EditLink";
import MetaTags from "../components/MetaTags";

const ProducerPage = () => {
  const { slug } = useParams();

  const { data: producerData, isSuccess: producerIsSuccess } =
    useGetProducerQuery(slug ?? "");

  return (
    <LoadingContainer
      loading={!producerIsSuccess}
      id="producerPage"
      breadcrumbsProps={{
        items: [
          { label: "Brands", url: "/brands" },
          producerData ? { label: producerData.producer.name } : undefined,
        ],
      }}
    >
      {producerIsSuccess && (
        <>
          <MetaTags
            title={
              producerData.producer.meta_title || producerData.producer.name
            }
            description={producerData.producer.meta_description ?? undefined}
          />
          <EditLink url={`/admin/producers/${producerData.producer.id}/edit`} />
          <Heading>{producerData.producer.name}</Heading>
          <SubpageDescription
            defaultDescription={producerData.producer.description}
            defaultImage={producerData.producer.logo_path}
          />
          <ProducerProducts producer={producerData.producer} />
          <Content>{producerData.producer.description_long}</Content>
        </>
      )}
    </LoadingContainer>
  );
};
export default ProducerPage;
