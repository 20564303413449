import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import OrderType from "../types/OrderType";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";

const ORDER_QUERY_KEY = "order-details";

interface OrderResponse {
  order: OrderType;
}

interface CreateOrderResponse {
  order: OrderType;
  payment_url: string;
}

export interface OrderParams {
  full_name: string;
  company_name: string;
  company_number: string;
  address_line1: string;
  address_line2: string;
  city: string;
  zipcode: string;
  phone_number: string;
  email: string;
  details: string;
  custom_delivery_address: boolean;
  delivery_full_name: string;
  delivery_company_name: string;
  delivery_company_number: string;
  delivery_address_line1: string;
  delivery_address_line2: string;
  delivery_city: string;
  delivery_zipcode: string;
  delivery_phone_number: string;
  delivery_email: string;
  delivery_details: string;
  accept_terms: boolean;
  stripe_token_id?: string;
}

interface PaypalOrderResponse {
  id: string;
}

interface PaypalConfirmOrderParams {
  orderId: string;
}

export const useGetOrderQuery = (id: number, token: string) => {
  return useQuery<OrderResponse, ApiError, OrderResponse>({
    queryKey: [ORDER_QUERY_KEY, { id, token }],
    queryFn: () => fetchFromApi(`/orders/${id}/${token}`),
  });
};

export const useCreateOrderMutation = (
  options?: Omit<
    UseMutationOptions<CreateOrderResponse, ApiError, OrderParams>,
    "mutationFn"
  >
) => {
  return useMutation({
    ...options,
    mutationFn: (order) => fetchFromApi(`/orders`, "POST", { order }),
  });
};

export const initPaypalOrder = () =>
  fetchFromApi<PaypalOrderResponse>(`/paypal/orders/init`, "POST");

export const confirmPaypalOrder = ({ orderId }: PaypalConfirmOrderParams) =>
  fetchFromApi<CreateOrderResponse>(`/paypal/orders/confirm`, "POST", {
    order_id: orderId,
  });
