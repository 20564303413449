import { Link } from "react-router-dom";
import ProducerType from "../../types/ProducerType";

import "./ProducerTile.scss";

interface ProducerTileProps {
  producer: ProducerType;
}

const ProducerTile = ({ producer }: ProducerTileProps) => {
  const producerUrl = `/brands/${producer.slug}`;
  return (
    <div className="producerTile">
      <div className="producerTile-image">
        {producer.medium_logo_path !== null ? (
          <Link to={producerUrl}>
            <img src={producer.medium_logo_path} alt={producer.name} />
          </Link>
        ) : null}
      </div>
      <h3 className="producerTile-name">
        <Link to={producerUrl}>{producer.name}</Link>
      </h3>

      <h5
        className="producerTile-description"
        dangerouslySetInnerHTML={{ __html: producer.description_short }}
      ></h5>

      <div className="producerTile-actions">
        <Link to={producerUrl} className="btn btn-more">
          Read more
        </Link>
      </div>
    </div>
  );
};
export default ProducerTile;
