import { useQueryClient } from "@tanstack/react-query";
import {
  useAddProductToWishlistMutation,
  useRemoveProductFromWishlistMutation,
} from "../../../api/wishlistApi";
import ProductType from "../../../types/ProductType";
import { useGetMyProfileQuery } from "../../../api/authApi";
import { useNavigate } from "react-router-dom";
import ecommerceGA from "../../../utils/ecommerceGA";

interface NavigationProps {
  product: ProductType;
}

const Navigation = ({ product }: NavigationProps) => {
  const { mutate: addToWishlist, isPending: addToWishlistIsPending } =
    useAddProductToWishlistMutation();
  const { mutate: removeFromWishlist, isPending: removeFromWishlistIsPending } =
    useRemoveProductFromWishlistMutation();
  const { currentCustomerIsLogged } = useGetMyProfileQuery();
  const navigate = useNavigate();

  const isLoading = addToWishlistIsPending || removeFromWishlistIsPending;

  const handleAddToWishlist = () => {
    if (currentCustomerIsLogged) {
      addToWishlist(
        { productId: product.id },
        {
          onSuccess: () => {
            ecommerceGA.addToWishlist(product);
          },
        }
      );
    } else {
      navigate("/login");
    }
  };

  const handleRemoveFromWishlist = () => {
    if (currentCustomerIsLogged) {
      removeFromWishlist({ productId: product.id });
    } else {
      navigate("/login");
    }
  };

  const handleAddToCompare = () => {
    if ("addToCompareList" in window) {
      (window.addToCompareList as (url: string) => void)(
        `/compare/${product.id}/add`
      );
    }
  };

  return (
    <div className="productListItem-navigation">
      <div className="productListItem-navigationItem">
        <button
          type="button"
          data-product-id={product.id}
          className="compare-link"
          onClick={handleAddToCompare}
        >
          Compare
        </button>
      </div>

      <div className="productListItem-navigationItem">
        {product.on_wishlist ? (
          <button
            type="button"
            onClick={handleRemoveFromWishlist}
            disabled={isLoading}
          >
            Remove from wishlist
          </button>
        ) : (
          <button
            type="button"
            onClick={handleAddToWishlist}
            disabled={isLoading}
          >
            Wish list
          </button>
        )}
      </div>
    </div>
  );
};
export default Navigation;
