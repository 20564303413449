import { useMutation } from "@tanstack/react-query";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";

export interface ResetPasswordParams {
  email: string;
}

export interface ChangePasswordParams {
  password: string;
  password_confirmation: string;
  reset_password_token: string;
}

export const useResetPasswordMutation = () => {
  return useMutation<void, ApiError, ResetPasswordParams>({
    mutationFn: (params) =>
      fetchFromApi(`/passwords/create`, "POST", { customer: params }),
  });
};

export const useChangePasswordMutation = () => {
  return useMutation<void, ApiError, ChangePasswordParams>({
    mutationFn: (params) =>
      fetchFromApi(`/passwords/update`, "POST", { customer: params }),
  });
};
