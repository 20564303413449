import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import SeriesType from "../types/SeriesType";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";
import PaginatedResponse from "./PaginatedResponse";
import ProductType from "../types/ProductType";

const SERIES_QUERY_KEY = "series";

interface SeriesResponse {
  series: SeriesType;
}
interface SeriesProductsResponse {
  products: ProductType[];
}

export const useGetSeriesQuery = (
  seriesId: string,
  options: Omit<
    UseQueryOptions<unknown, ApiError, SeriesResponse>,
    "queryKey" | "QueryFn"
  > = {}
) => {
  return useQuery<unknown, ApiError, SeriesResponse>({
    ...options,
    queryKey: [SERIES_QUERY_KEY, { seriesId }],
    queryFn: () => fetchFromApi(`/series/${seriesId}`),
  });
};

export const useGetSeriesProductsQuery = (seriesId: string, page = 1) => {
  return useQuery<unknown, ApiError, PaginatedResponse<SeriesProductsResponse>>(
    {
      queryKey: [SERIES_QUERY_KEY, seriesId, "products", page],
      queryFn: () => fetchFromApi(`/series/${seriesId}/products?page=${page}`),
    }
  );
};
