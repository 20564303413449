import {
  useDestroyBasketItemQuery,
  useUpdateBasketItemQuery,
} from "../../api/basketApi";
import ProductType from "../../types/ProductType";

import "./QuantityInput.scss";

interface QuantityInputProps {
  product: ProductType;
  quantity: number;
}

const QuantityInput = ({ product, quantity }: QuantityInputProps) => {
  const { mutate: updateProduct } = useUpdateBasketItemQuery();
  const { mutate: removeProduct } = useDestroyBasketItemQuery();

  const handleAdd = () => {
    updateProduct({ productId: product.id, quantity: quantity + 1 });
  };
  const handleRemove = () => {
    removeProduct({ productId: product.id, quantity: 1 });
  };
  return (
    <div className="quantityInput">
      <div className="quantityInput-value">{quantity}</div>
      <button
        className="quantityInput-button plus"
        type="button"
        onClick={handleAdd}
      >
        +
      </button>
      <button
        className="quantityInput-button minus"
        type="button"
        onClick={handleRemove}
      >
        -
      </button>
    </div>
  );
};
export default QuantityInput;
