import { useGetProducerProductsQuery } from "../../api/producersApi";
import type ProducerType from "../../types/ProducerType";
import Pagination from "../Pagination";
import ProductsList from "../Products/ProductsList";

interface ProducerProductsProps {
  producer: ProducerType;
}

const ProducerProducts = ({ producer }: ProducerProductsProps) => {
  const query = new URLSearchParams(location.search);
  const page = (query.get("page") ?? 1) as number;

  const { data: productsData } = useGetProducerProductsQuery(
    producer.slug,
    page
  );

  return (
    <div className="producerProducts">
      <Pagination url={`/brands/${producer.slug}`} meta={productsData?.meta} />
      <ProductsList products={productsData?.products} />
      <Pagination url={`/brands/${producer.slug}`} meta={productsData?.meta} />
    </div>
  );
};
export default ProducerProducts;
