import type ProductType from "../../../types/ProductType";
import Actions from "./Actions";
import Image from "./Image";
import Navigation from "./Navigation";
import Price from "./Price";
import Title from "./Title";

import "./index.scss";

interface ProductProps {
  product: ProductType;
}

const Product = ({ product }: ProductProps) => {
  return (
    <div className="productListItem">
      <Image product={product} />
      <Navigation product={product} />
      <Title product={product} />
      <Price product={product} />
      <Actions product={product} />
    </div>
  );
};
export default Product;
