import { useEffect, useState } from "react";
import { useGetBasketQuery } from "../api/basketApi";
import LoginForm from "../components/Auth/LoginForm";
import RegisterForm from "../components/Auth/RegisterForm";
import BasketSummary from "../components/Basket/BasketSummary";
import LoadingContainer from "../components/LoadingContainer";
import OrderItems from "../components/NewOrder/OrderItems";

import "./NewOrderPage.scss";
import OrderFormOptions from "../components/NewOrder/OrderFormOptions";
import { useGetMyProfileQuery } from "../api/authApi";
import OrderForm from "../components/NewOrder/OrderForm";
import { useCreateOrderMutation } from "../api/ordersApi";
import Loader from "../components/Loader";
import ecommerceGA from "../utils/ecommerceGA";

type FormType = "login" | "register" | "order";

const NewOrderPage = () => {
  const [loading, setLoading] = useState(false);
  const [visibleForm, setVisibleForm] = useState<FormType>();

  const { data: basketData, isSuccess: basketIsSuccess } = useGetBasketQuery();
  const { currentCustomerIsLogged, isSuccess: currentCustomerIsSuccess } =
    useGetMyProfileQuery();

  const { isPending: createOrderIsPending } = useCreateOrderMutation();

  useEffect(() => {
    if (currentCustomerIsLogged) {
      setVisibleForm("order");
    }
  }, [currentCustomerIsLogged]);

  useEffect(() => {
    if (basketIsSuccess) {
      ecommerceGA.beginCheckout(basketData.basket);
    }
  }, [basketIsSuccess]);

  const onSuccess = () => setLoading(false);

  const isLoading =
    !basketIsSuccess || !currentCustomerIsSuccess || createOrderIsPending;

  return (
    <LoadingContainer
      loading={isLoading}
      id="newOrderPage"
      breadcrumbsProps={{ items: [{ label: "New order", url: "/checkout" }] }}
    >
      <Loader loading={loading} />
      {basketIsSuccess && currentCustomerIsSuccess && (
        <div className="newOrderColumns">
          <div>
            {!currentCustomerIsLogged && (
              <OrderFormOptions value={visibleForm} onChange={setVisibleForm} />
            )}

            {visibleForm === "login" && (
              <LoginForm setLoading={setLoading} onSuccess={onSuccess} />
            )}
            {visibleForm === "register" && (
              <RegisterForm onSuccess={onSuccess} />
            )}

            {visibleForm === "order" && <OrderForm />}
          </div>
          <div>
            <OrderItems orderItems={basketData.basket.basket_items} />
            <BasketSummary basket={basketData.basket} />
          </div>
        </div>
      )}
    </LoadingContainer>
  );
};

export default NewOrderPage;
